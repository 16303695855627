import { db } from "../firebase/firebase";
import firebase from "firebase";
import { getFileName, uploadFileToS3 } from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";
import { getDate } from "../helpers/getDate";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const GET_PAYMENT = "GET_PAYMENT";
export const SELECT_INSTITUTE = "SELECT_INSTITUTE";

const start = () => {
  return {
    type: START,
  };
};
const success = (payment) => {
  return {
    type: SUCCESS,
    payload: payment,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

export const fetchPayments = (payments) => {
  return {
    type: FETCH_PAYMENTS,
    payload: payments,
  };
};
const fetchPaymentInfo = (payment) => {
  return {
    type: GET_PAYMENT,
    payload: payment,
  };
};

export const selectInstitute = (institute) => {
  return {
    type: SELECT_INSTITUTE,
    payload: institute,
  };
};

export const getPayments = (filter) => (dispatch, getState) => {
  dispatch(start());
  let collectionRef = db.collection("payments");
  if (filter.type && filter.type !== "") {
    collectionRef = collectionRef.where("asset_type", "==", filter.type);
  }
  if (filter.date && filter.date !== "") {
    collectionRef = collectionRef.where(
      "timestamp",
      ">=",
      firebase.firestore.Timestamp.fromDate(new Date(getDate(filter.date)))
    );
  }

  if (filter.payment_method.length > 0) {
    let payment_method;
    if (filter.payment_method === "international") {
      payment_method = ["sslcommerz"];
    } else {
      payment_method = ["esewa", "khalti"];
    }
    collectionRef = collectionRef.where("payment_method", "in", payment_method);
  }
  collectionRef.onSnapshot((querySnapshot) => {
    const payments = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    dispatch(fetchPayments(payments));
    dispatch(success());
  });
};

export const getPaymentDetails = () => (dispatch, getState) => {
  dispatch(start());
  const institute = getState().payments.institute.id;
  db.collection(`payments`)
    .doc(institute)
    .onSnapshot((querySnapshot) => {
      const paymentInfo = { ...querySnapshot.data(), id: querySnapshot.id };
      dispatch(fetchPaymentInfo(paymentInfo));
      dispatch(success());
    });
};

export const addPayment = (data) => async (dispatch) => {
  dispatch(start());
  try {
    const url = await uploadFileToS3(data.image, uuidv4());
    await db.collection(`payments/${data.institute.value}/payments`).add({
      ...data,
      image: url,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });

    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const setInstitute = (institute) => (dispatch, getState) => {
  dispatch(selectInstitute(institute));
};

export const updatePayment =
  ({ old_url, ...data }, id) =>
  async (dispatch, getState) => {
    dispatch(start());
    try {
      if (data.image) {
        const url = await uploadFileToS3(
          data.image,
          getFileName(old_url) || uuidv4()
        );

        await db
          .collection(`payments/${data.institute.value}/payments`)
          .doc(id)
          .update({
            ...data,
            image: url,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        await db
          .collection(`payments/${data.institute.value}/payments`)
          .doc(id)
          .update({
            ...data,
            created_at: firebase.firestore.FieldValue.serverTimestamp(),
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }

      dispatch(success());
    } catch (err) {
      dispatch(error());
    }
  };

export const deletePayment = (payment) => (dispatch) => {
  dispatch(start());
  db.collection(`payments/${payment.institute.value}/payments`)
    .doc(payment.id)
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const changePaymentStatus = (status, id) => (dispatch, getState) => {
  dispatch(start());
  const institute = getState().payments.institute.id;

  db.collection(`payments/${institute}/payments`)
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};
