import {
  FETCH_RESULT,
  RESULT_ERROR,
  RESULT_START,
  RESULT_SUCCESS,
} from "../actions/result";

const initialState = {
  started: false,
  success: false,
  error: false,
  loading: false,
  results: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESULT_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };

    case RESULT_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };

    case RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };

    case FETCH_RESULT:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        results: action.payload,
      };

    default:
      return state;
  }
};
