import { db } from "../firebase/firebase";
import firebase from "firebase";
import { uploadImage, deleteImage, getFileFromURL } from "./common";
import {
  deleteFileFromS3,
  getFileName,
  uploadFileToS3,
} from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ADD_UNITS = "ADD_UNITS";
export const FETCH_UNITS = "FETCH_UNITS";
export const FETCH_SUB_UNITS = "FETCH_SUB_UNITS";
export const FETCH_LESSONS = "FETCH_LESSONS";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const fetchUnits = (units) => {
  return {
    type: FETCH_UNITS,
    payload: units,
  };
};
const fetchSubUnits = (subunits) => {
  return {
    type: FETCH_SUB_UNITS,
    payload: subunits,
  };
};
const fetchLessons = (lessons) => {
  return {
    type: FETCH_LESSONS,
    payload: lessons,
  };
};

export const addUnit = (unit) => async (dispatch) => {
  const { coverImageURL, coverImageURLToDelete, ...remainingUnitValue } = unit;
  dispatch(start());

  // upload image

  try {
    if (coverImageURL !== null) {
      const coverImage = await uploadFileToS3(coverImageURL, uuidv4());
      remainingUnitValue.coverImageURL = coverImage;
    }

    await db.collection(process.env.REACT_APP_UNITS).add({
      ...remainingUnitValue,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(success());
  } catch (error) {
    dispatch(error());
  }
};

export const addSubUnit = (subunit, unitId) => async (dispatch) => {
  dispatch(start());
  const uploadPromises = subunit.audios.map((audio) => {
    return uploadFileToS3(audio.audio, uuidv4());
  });

  const audios = await Promise.all(uploadPromises);
  subunit.audios = subunit.audios.map((audio, i) => {
    audio.audio = audios[i];
    return audio;
  });

  db.collection(`${process.env.REACT_APP_UNITS}/${unitId}/subunits`)
    .add({
      ...subunit,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};
export const addLesson = (lesson, unitId, subUnitId) => async (dispatch) => {
  dispatch(start());
  try {
    const url = await uploadFileToS3(lesson.pdf, uuidv4());
    await db
      .collection(
        `${process.env.REACT_APP_UNITS}/${unitId}/subunits/${subUnitId}/lessons`
      )
      .add({
        ...lesson,
        pdf: url,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    dispatch(success());
  } catch (error) {
    dispatch(error());
  }
};

export const getAllUnits = (unitsParam) => (dispatch) => {
  const {
    lastRefOfUnitDoc,
    storeLastRefOfUnitDoc,
    limitTofetch,
    previousFetchedUnits = [],
  } = unitsParam;
  dispatch(start());

  let unitRef = db
    .collection(process.env.REACT_APP_UNITS)
    .orderBy("created_at", "desc")
    .limit(limitTofetch);

  if (lastRefOfUnitDoc) {
    unitRef = unitRef.startAfter(lastRefOfUnitDoc);
  }

  unitRef.onSnapshot((querySnapshot) => {
    storeLastRefOfUnitDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    const units = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    dispatch(fetchUnits([...previousFetchedUnits, ...units]));
    dispatch(success());
  });
};
export const getAllSubUnits = (unitId) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_UNITS + "/" + unitId + "/subunits")
    .orderBy("created_at", "desc")
    .onSnapshot((querySnapshot) => {
      const subunits = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchSubUnits(subunits));
      dispatch(success());
    });
};
export const getAllLessons = (unitId, subunitId) => (dispatch) => {
  dispatch(start());
  db.collection(
    process.env.REACT_APP_UNITS +
      "/" +
      unitId +
      "/subunits/" +
      subunitId +
      "/lessons"
  )
    .orderBy("created_at", "desc")
    .onSnapshot((querySnapshot) => {
      const lessons = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchLessons(lessons));
      dispatch(success());
    });
};

export const deleteLesson = (lesson, unitId, subUnitId) => (dispatch) => {
  dispatch(start());
  db.collection(
    `${process.env.REACT_APP_UNITS}/${unitId}/subunits/${subUnitId}/lessons`
  )
    .doc(lesson[0])
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};
export const deleteSubUnit = (subUnit, unitId) => async (dispatch) => {
  // delete images
  dispatch(start());

  const audios = subUnit[7];

  if (audios.length > 0) {
    const deleteAudio = audios.map((audio) => {
      return deleteFileFromS3(audio.audio_name);
    });

    await Promise.all(deleteAudio);
  }
  db.collection(`${process.env.REACT_APP_UNITS}/${unitId}/subunits`)
    .doc(subUnit[0])
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};
export const deleteUnit = (unit) => async (dispatch) => {
  dispatch(start());
  if (unit[4] !== null) {
    const files = unit[4].split("/");
    const imageName = files[files.length - 1];
    const imagePath = `${process.env.REACT_APP_STORAGE}/${imageName}`;
    await deleteFileFromS3(imagePath);
  }
  db.collection(`${process.env.REACT_APP_UNITS}`)
    .doc(unit[0])
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const editLesson =
  (lesson, unitId, subUnitId, lessonId) => async (dispatch) => {
    dispatch(start());
    const { pdf, haspdf, ...update } = lesson;
    try {
      if (pdf) {
        const url = await uploadFileToS3(pdf, getFileName(haspdf));
        await db
          .collection(
            `${process.env.REACT_APP_UNITS}/${unitId}/subunits/${subUnitId}/lessons`
          )
          .doc(lessonId)
          .update({
            ...lesson,
            pdf: url,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        dispatch(success());
      } else {
        await db
          .collection(
            `${process.env.REACT_APP_UNITS}/${unitId}/subunits/${subUnitId}/lessons`
          )
          .doc(lessonId)
          .update({
            ...update,
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
        dispatch(success());
      }
    } catch (err) {
      dispatch(error());
    }
  };

export const editSubUnit = (subunit, unitId, subunitId) => async (dispatch) => {
  dispatch(start());

  const { audiosUrlToDelete, ...remainingSubUnit } = subunit;

  const previousAudios = subunit.audios.filter(
    (audio) => typeof audio.audio === "string"
  );

  const latestAudios = subunit?.audios.filter(
    (a) => typeof a.audio === "object"
  );

  const uploadPromises = latestAudios.map((audio) => {
    return uploadFileToS3(audio.audio, uuidv4());
  });

  const audios = await Promise.all(uploadPromises);
  const newAudios = latestAudios.map((audio, i) => {
    audio.audio = audios[i];
    return audio;
  });

  remainingSubUnit.audios = [...previousAudios, ...newAudios];

  if (audiosUrlToDelete.length > 0) {
    const deleteAudio = audiosUrlToDelete
      .filter((audio) => typeof audio.audio === "string")
      .map((audio) => {
        return deleteFileFromS3(audio.audio_name);
      });

    await Promise.all(deleteAudio);
  }

  // const uploadPromises = subunit.audios.map((audio) => {
  //   if (audio.audio instanceof File) {
  //     return uploadFileToS3(audio.audio, uuidv4());
  //   } else {
  //     return Promise.resolve(audio.audio);
  //   }
  // });

  // const audios = await Promise.all(uploadPromises);
  // subunit.audios = subunit.audios.map((audio, i) => {
  //   audio.audio = audios[i];
  //   return audio;
  // });

  db.collection(`${process.env.REACT_APP_UNITS}/${unitId}/subunits`)
    .doc(subunitId)
    .update({
      ...remainingSubUnit,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};
export const editUnit = (unit, unitId, cb) => async (dispatch) => {
  const { coverImageURLToDelete, ...remainingUnit } = unit;
  dispatch(start());

  try {
    if (coverImageURLToDelete !== null) {
      const files = coverImageURLToDelete.split("/");
      const imageName = files[files.length - 1];
      const imagePath = `${process.env.REACT_APP_STORAGE}/${imageName}`;
      await deleteFileFromS3(imagePath);
    }

    if (typeof remainingUnit.coverImageURL === "object") {
      const coverImage = await uploadFileToS3(
        remainingUnit.coverImageURL,
        uuidv4()
      );
      remainingUnit.coverImageURL = coverImage;
    }

    await db
      .collection(`${process.env.REACT_APP_UNITS}`)
      .doc(unitId)
      .update({
        ...remainingUnit,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    cb({ ...unit, ...remainingUnit, id: unitId });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const changeUnitStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_UNITS)
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const changeSubUnitStatus =
  (status, unitId, subunitId) => (dispatch) => {
    dispatch(start());
    db.collection(`${process.env.REACT_APP_UNITS}/${unitId}/subunits`)
      .doc(subunitId)
      .update({
        status: status,
      })
      .then((data) => {
        dispatch(success());
      })
      .catch((err) => dispatch(error()));
  };

export const changeLessonStatus =
  (status, unitId, subunitId, lessonId) => (dispatch) => {
    dispatch(start());
    db.collection(
      `${process.env.REACT_APP_UNITS}/${unitId}/subunits/${subunitId}/lessons`
    )
      .doc(lessonId)
      .update({
        status: status,
      })
      .then((data) => {
        dispatch(success());
      })
      .catch((err) => dispatch(error()));
  };
