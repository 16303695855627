import { db } from "../firebase/firebase";

export const POLL_QUESTION_START = "POLL_QUESTION_START";
export const POLL_QUESTION_ERROR = "POLL_QUESTION_ERROR";
export const FETCH_POLL_QUESTION = "FETCH_POLL_QUESTION";

const start = () => {
  return {
    type: POLL_QUESTION_START,
  };
};

const error = () => {
  return {
    type: POLL_QUESTION_ERROR,
  };
};

const fetchPollQuestions = (payload) => {
  return {
    type: FETCH_POLL_QUESTION,
    payload,
  };
};

export const getAllPollQuestions =
  ({
    storeRefOfQuestionDoc,
    lastRefOfQuestionDoc,
    limitTofetch,
    questions = [],
  }) =>
  async (dispatch) => {
    let pollQuestionsRef = db
      .collection("pollQuestions")
      .orderBy("createdAt", "desc")
      .limit(limitTofetch);

    if (lastRefOfQuestionDoc) {
      pollQuestionsRef = pollQuestionsRef.startAfter(lastRefOfQuestionDoc);
    }

    pollQuestionsRef.onSnapshot((querySnapshot) => {
      const resultsData = [];
      querySnapshot.docs.forEach((doc) => {
        resultsData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      storeRefOfQuestionDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      dispatch(fetchPollQuestions([...questions, ...resultsData]));
    });
  };
