import { db } from "../firebase/firebase";

export const PARTNERSHIP_REQUEST_START = "PARTNERSHIP_REQUEST_START";
export const PARTNERSHIP_REQUEST_ERROR = "PARTNERSHIP_REQUEST_ERROR";
export const FETCH_PARTNERSHIP_REQUEST = "FETCH_PARTNERSHIP_REQUEST";

const start = () => {
  return {
    type: PARTNERSHIP_REQUEST_START,
  };
};

const error = () => {
  return {
    type: PARTNERSHIP_REQUEST_ERROR,
  };
};

const fetchPartnerShipRequests = (payload) => {
  return {
    type: FETCH_PARTNERSHIP_REQUEST,
    payload,
  };
};

export const getPartnerShipRequests =
  ({
    storeRefOfRequestDoc,
    lastRefOfRequestDoc,
    limitTofetch,
    requests = [],
  }) =>
  async (dispatch) => {
    dispatch(start());
    let requestsDoc = db.collection("partnership_requests").limit(limitTofetch);

    if (lastRefOfRequestDoc) {
      requestsDoc = requestsDoc.startAfter(lastRefOfRequestDoc);
    }

    requestsDoc.onSnapshot((querySnapshot) => {
      const partnerData = [];
      storeRefOfRequestDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.docs.forEach((doc) => {
        partnerData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      dispatch(fetchPartnerShipRequests([...requests, ...partnerData]));
    });
  };
