import axios from "axios";
import { db, storage } from "../firebase/firebase";
import firebase from "firebase";
import config from "../config/config";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_INSTITUTE_LOGINS = "FETCH_INSTITUTE_LOGINS";
export const GET_INSTITUTE = "GET_INSTITUTE";
export const FETCH_INSTITUTE_REQUEST = "FETCH_INSTITUTE_REQUEST";

export const PLAYED_MODEL_SET = "PLAYED_MODEL_SET";
const start = () => {
  return {
    type: START,
  };
};
const success = (setting) => {
  return {
    type: SUCCESS,
    payload: setting,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const instituteRequest = (instituteRequests) => {
  return {
    type: FETCH_INSTITUTE_REQUEST,
    payload: instituteRequests,
  };
};

const fetchInstituteLogins = (institutes) => {
  return {
    type: FETCH_INSTITUTE_LOGINS,
    payload: institutes,
  };
};
const getInstitute = (institute) => {
  return {
    type: GET_INSTITUTE,
    payload: institute,
  };
};

const getPlayedModelSetByInstituteId = (instituteId, response) => {
  return {
    type: PLAYED_MODEL_SET,
    payload: {
      instituteId,
      data: response,
    },
  };
};

export const getInstituteLogins = () => (dispatch) => {
  dispatch(start());

  db.collection("users")
    .where("role", "==", "institute")
    .orderBy("created_at", "desc")
    .onSnapshot((querySnapshot) => {
      const institutes = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchInstituteLogins(institutes));
      dispatch(success());
    });
};

export const changeLoginStatus = (status, id) => async (dispatch) => {
  try {
    dispatch(start());
    await db.collection("users").doc(id).update({
      status: status,
    });
  } catch (err) {
    dispatch(error());
  }
};

export const getModelSetByInstituteId = (id, query) => async (dispatch) => {
  dispatch(start());

  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${config.api_url}/v1/private/epsTopikStudentQuizPlayedInfo`,
      {
        params: {
          ...query,
          instituteId: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(getPlayedModelSetByInstituteId(id, response.data));
  } catch (error) {
    dispatch(error());
  }
};

export const getInstituteById = (id) => async (dispatch) => {
  dispatch(start());
  try {
    db.collection("institutes")
      .doc(id)
      .onSnapshot((snap) => {
        if (snap.exists) {
          const data = snap.data();
          dispatch(getInstitute({ ...data, id: snap.id }));
        }
        dispatch(success());
      });
  } catch (error) {
    dispatch(error());
  }
};

export const getAllInstituteRequests =
  (instituteRequestParam) => (dispatch) => {
    dispatch(start());
    const {
      limit,
      lastRefOfDoc,
      storeLastRefOfDoc,
      setStopFetching,
      previouseInstituteRequestsData = [],
    } = instituteRequestParam;
    let instituteQuery = db
      .collection("institute_registration_requests")
      .limit(limit);

    if (lastRefOfDoc) {
      instituteQuery.startAfter(lastRefOfDoc);
    }

    instituteQuery.onSnapshot((querySnapshot) => {
      const partnerData = [];
      storeLastRefOfDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.docs.forEach((doc) => {
        partnerData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setStopFetching(partnerData.length === 0);
      dispatch(
        instituteRequest([...previouseInstituteRequestsData, ...partnerData])
      );
    });
  };
