export const OPEN_IMAGE_PREVIEW = 'OPEN_IMAGE_PREVIEW';
export const CLOSE_IMAGE_PREVIEW = 'CLOSE_IMAGE_PREVIEW';

const preview = (url, sourceType) => {
  return {
    type: OPEN_IMAGE_PREVIEW,
    url: url,
    previewSourceType: sourceType
  };
};
const closePreview = () => {
  return {
    type: CLOSE_IMAGE_PREVIEW
  };
};

export {
  preview,
  closePreview
}
