import s3 from "../config/DigitalOcean";
import Config from "../config/config";

const uploadFileToS3 = (file, fname) => {
  return new Promise((resolve, reject) => {
    if (file == "" || file == null) {
      return resolve(null);
    }
    const fileName = file.name;
    var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    const name = `${process.env.REACT_APP_STORAGE}/${fname}.${ext}`;
    const params = {
      Body: file,
      Bucket: `${Config.bucketName}`,
      Key: name,
    };

    // Sending the file to the Spaces

    s3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`;
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = file.type;
        request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
        request.httpRequest.headers["x-amz-acl"] = "public-read";
      })
      .send((err) => {
        if (err) reject(err);
        else {
          // If there is no error updating the editor with the imageUrl
          const imageUrl = `${Config.digitalOceanSpaces}/${name}`;
          resolve(imageUrl);
        }
      });
  });
};

const getFileName = (url) => {
  const fileArray = (url || "").split("nyc3.digitaloceanspaces.com/");
  if (fileArray.length > 1) {
    return fileArray[1].split("/").pop().split(".")[0];
  } else {
    return null;
  }
};

const uploadInstituteResource = (file, fname, instituteId, destination, cb) => {
  return new Promise((resolve, reject) => {
    if (file == "" || file == null) {
      return resolve(null);
    }
    const fileName = file.name;
    var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    let name;
    if (instituteId) {
      name = `${process.env.REACT_APP_STORAGE}/${destination}/${instituteId}/${fname}.${ext}`;
    } else {
      name = `${process.env.REACT_APP_STORAGE}/${destination}/${fname}.${ext}`;
    }
    const params = {
      Body: file,
      Bucket: `${Config.bucketName}`,
      Key: name,
    };

    // Sending the file to the Spaces

    s3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `${Config.digitalOceanSpaces}`;
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = file.type;
        request.httpRequest.headers["Access-Control-Allow-Origin"] = "*";
        request.httpRequest.headers["x-amz-acl"] = "public-read";
      })
      .on("httpUploadProgress", (progress) => {
        let progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );

        if (cb !== undefined) {
          cb(progressPercentage);
        }
      })
      .send((err) => {
        if (err) {
          reject(err);
        } else {
          // If there is no error updating the editor with the imageUrl
          const imageUrl = `${Config.digitalOceanSpaces}/${name}`;
          resolve(imageUrl);
        }
      });
  });
};

const deleteFileFromS3 = (fileName) => {
  return new Promise((resolve, reject) => {
    if (!fileName) return resolve(true);
    const params = {
      Bucket: `${Config.bucketName}`,
      Key: fileName,
    };

    s3.deleteObject(params, function (err, data) {
      if (err) reject(err); // an error occurred
      else resolve(data); // successful response
    });
  });
};

export {
  deleteFileFromS3,
  getFileName,
  uploadFileToS3,
  uploadInstituteResource,
};
