import {
  START,
  SUCCESS,
  ERROR,
  FETCH_SUBSCRIPTION,
  FETCH_NEW_SUBSCRIPTION,
} from "../actions/subscriptions";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    subscription: [],
    newSubscriptionsByPlaylistId: {}
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        subscription: action.payload,
      };

    case FETCH_NEW_SUBSCRIPTION:

      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newSubscriptionsByPlaylistId: {
          ...state.newSubscriptionsByPlaylistId,
          [action.payload.playlistId]: action.payload.playlists
        },
      };

    default:
      return state;
  }
};
