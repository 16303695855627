import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";
import { db, storage } from "../firebase/firebase";
import { convertExcelToJson } from "../helpers/convertExcelToJson";
import { getFileName, uploadFileToS3 } from "../helpers/awsHelper";

export const MODEL_SET_START = "MODEL_SET_START";
export const MODEL_SET_ERROR = "MODEL_SET_ERROR";
export const MODEL_SET_SUCCESS = "MODEL_SET_SUCCESS";

export const MODEL_SET_BY_ID = "MODEL_SET_BY_ID";

export const MODEL_DETAIL = "MODEL_DETAIL";
export const FETCH_MODEL_SET = "FETCH_MODEL_SET";
export const GET_MODEL_SCORE = "GET_MODEL_SCORE";
export const GET_MODEL_GROUPS = "GET_MODEL_GROUPS";
export const SET_SCORE_REVIEW = "SET_SCORE_REVIEW";
export const ADD_MODEL_QUESTION = "ADD_MODEL_QUESTION";
export const GET_MODEL_QUESTIONS = "GET_MODEL_QUESTIONS";
export const FETCH_MODEL_SET_FOR_PAGINATION = "FETCH_MODEL_SET_FOR_PAGINATION";

const start = () => {
  return {
    type: MODEL_SET_START,
  };
};

const success = (modelset) => {
  return {
    type: MODEL_SET_SUCCESS,
    modelset,
  };
};
const error = () => {
  return {
    type: MODEL_SET_ERROR,
  };
};

const fetchSets = (modelsets) => {
  return {
    type: FETCH_MODEL_SET,
    modelsets,
  };
};

const fetchSetsForPagination = (modelSets) => {
  return {
    type: FETCH_MODEL_SET_FOR_PAGINATION,
    modelSetsForPaginaiton: modelSets,
  };
};

const modelsetQuestions = (model_questions) => {
  return {
    type: GET_MODEL_QUESTIONS,
    model_questions,
  };
};

const modelsetGroups = (model_groups) => {
  return {
    type: GET_MODEL_GROUPS,
    model_groups,
  };
};

const add_model_question = (model_question) => {
  return {
    type: ADD_MODEL_QUESTION,
    model_question,
  };
};
const getModelDetail = (detail) => {
  return {
    type: MODEL_DETAIL,
    modelDetail: detail,
  };
};

const fetchModelSetById = (modelSet) => {
  return {
    type: MODEL_SET_BY_ID,
    payload: {
      id: modelSet.id,
      modelSet,
    },
  };
};

const getScore = (scores) => {
  return {
    type: GET_MODEL_SCORE,
    scores,
  };
};

const setScoreReview = (scoreReviews) => {
  return {
    type: SET_SCORE_REVIEW,
    scoreReviews,
  };
};

const uploadImage = (image_name, image, collection) => {
  return image
    ? new Promise((resolve, reject) => {
        const uploadImage = storage
          .ref(`${collection}/${image_name}`)
          .put(image);
        uploadImage.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
          },
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref(collection)
              .child(image_name)
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              });
          }
        );
      })
    : new Promise((resolve, reject) => resolve(null));
};

const deleteImage = (image_name, collection) => {
  return image_name
    ? new Promise((resolve, reject) => {
        storage
          .ref(collection)
          .child(image_name)
          .delete()
          .then((data) => {
            return resolve(data);
          });
      })
    : new Promise((resolve, reject) => resolve("No image"));
};

export const fetchModelSetsTotalSize = (instituteId) => async (dispatch) => {
  try {
    let query;
    if (instituteId) {
      query = db.collection("model_sets");
      query = query.where("institute_id", "==", instituteId);
    }
    const totalSize = (await query.get()).size;

    dispatch(fetchSets(totalSize));
  } catch (err) {
    dispatch(error());
  }
};

export const fetchModelSets = (modelSetParam) => async (dispatch) => {
  const {
    instituteId,
    order = "asc",
    storeLastIndexRefOfDoc,
    limit,
    setStoreLastIndexRefOfDoc,
    setStopFetching,
    oldModelSetWithPage,
  } = modelSetParam ?? {};

  dispatch(start());
  try {
    let query = db.collection("model_sets").orderBy("added_date", "desc");

    if (limit) {
      query = query.limit(limit);
    }

    if (instituteId) {
      query = query.where("institute_id", "==", instituteId);
    }

    if (storeLastIndexRefOfDoc !== undefined) {
      query = query.startAfter(storeLastIndexRefOfDoc);
    }

    const querySnapshot = await query.get();
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (setStoreLastIndexRefOfDoc) {
      setStoreLastIndexRefOfDoc(lastVisible);
    }
    const modelSets = querySnapshot.docs.map((modelSet) => ({
      ...modelSet.data(),
      id: modelSet.id,
    }));
    setStopFetching(modelSets?.length === 0);
    dispatch(fetchSetsForPagination([...oldModelSetWithPage, ...modelSets]));
    dispatch(success());
    modelSets.forEach((set) => {
      dispatch(fetchModelSetById(set));
    });
  } catch (error) {
    console.error(error);
    dispatch(error());
  }
};

export const addModelSet = (modelSetParam) => (dispatch, getState) => {
  const { data: modelset, ownProps, modelSets } = modelSetParam;
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .add({
      ...modelset,
      institute_id: modelset.institute.value,
      added_date: firebase.firestore.FieldValue.serverTimestamp(),
      last_updated: firebase.firestore.FieldValue.serverTimestamp(),
      created_by: getState().auth.user.uid,
      audio: "",
    })
    .then((querySnapshot) => {
      dispatch(
        fetchSetsForPagination([
          {
            ...modelset,
            institute_id: modelset.institute.value,
            added_date: firebase.firestore.FieldValue.serverTimestamp(),
            last_updated: firebase.firestore.FieldValue.serverTimestamp(),
            created_by: getState().auth.user.uid,
            audio: "",
            id: querySnapshot.id,
          },
          ...modelSets,
        ])
      );

      dispatch(success({ ...modelset, id: querySnapshot.id }));
      ownProps.history.push("/modelset/list/" + querySnapshot.id);
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const modelDetail = (id) => async (dispatch) => {
  dispatch(start());

  try {
    const querySnapshot = await db
      .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
      .doc(id)
      .get();
    dispatch(getModelDetail(querySnapshot.data()));
  } catch (err) {
    dispatch(error());
  }
};

export const editModelSet = (data, id, sets) => (dispatch) => {
  dispatch(start());
  let updateData = {
    ...data,
    title: data.title,
    subtitle: data.subtitle ?? "",
    institute_id: data?.institute?.value ?? null,
    type: data.type ?? "",
    model_type: data?.model_type ?? "",
    price: data.price ?? "",
    emails: data.emails ?? [],
    audio_repeatation: data?.audio_repeatation ?? null,
    last_updated: firebase.firestore.FieldValue.serverTimestamp(),
  };

  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(id)
    .update({ ...updateData })
    .then((data) => {
      dispatch(modelDetail(id));
      const updatedSets = sets.map((model_set) => {
        if (model_set.id === id) {
          return { ...updateData, id };
        }
        return model_set;
      });
      dispatch(fetchSetsForPagination(updatedSets));
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const updateModelImage = (data, id) => async (dispatch) => {
  dispatch(start());
  try {
    const url = await uploadFileToS3(
      data.audio,
      getFileName(data.deleteImage) || uuidv4()
    );
    await db
      .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
      .doc(id)
      .update({ audio: url });
    dispatch(modelDetail(id));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const changeModelStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const deleteModelSet = (set, modelSets) => (dispatch) => {
  const filteredSets = modelSets.filter((sets) => sets.id !== set[0]);
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(set[0])
    .collection(process.env.REACT_APP_MODEL_QUESTIONS)
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        data.docs.map((el) => {
          db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
            .doc(set[0])
            .collection(process.env.REACT_APP_MODEL_QUESTIONS)
            .doc(el.id)
            .delete()
            .then(() => {
              db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
                .doc(set[0])
                .delete()
                .then(() => {
                  dispatch(success());
                });
            })
            .catch((err) => console.log("err", err));
        });
      } else {
        db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
          .doc(set[0])
          .delete()
          .then(() => {
            dispatch(success());
          });
      }
      dispatch(fetchSetsForPagination(filteredSets));
    })
    .catch((err) => {
      alert(err);
    });
};

export const addGroup = (group, ownProps) => (dispatch) => {
  dispatch(start());
  uploadImage(
    group.image_name,
    group.image,
    process.env.REACT_APP_GROUP_IMAGE
  ).then((image) => {
    uploadImage(
      group.audio_name,
      group.audio,
      process.env.REACT_APP_GROUP_IMAGE
    ).then((audio) => {
      db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
        .doc(ownProps.match.params.id)
        .collection(process.env.REACT_APP_GROUP_COLLECTION)
        .add({
          text: group.text,
          audio: audio,
          image: image,
        })
        .then((querySnapshot) => {
          dispatch(success({ id: querySnapshot.id }));
          //   ownProps.history.push("/modelset/list/" + querySnapshot.id);
        })
        .catch((err) => {
          dispatch(error());
        });
    });
  });
};

export const getGroups = (id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(id)
    .collection(process.env.REACT_APP_GROUP_COLLECTION)
    .get()
    .then((data) => {
      const groups = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      dispatch(modelsetGroups(groups));
    });
};

export const addModelQuestion = (data, bool, ownProps) => async (dispatch) => {
  try {
    dispatch(start());
    let options_meta = [];
    let answers = [];
    if (data.option_type === "image" || data.option_type === "audio") {
      options_meta = [
        { image_name: data.answer1.image },
        { image_name: data.answer2.image },
        { image_name: data.answer3.image },
        { image_name: data.answer4.image },
      ];
      answers = [{ image_name: data.answer }];
    } else {
      options_meta = [data.answer1, data.answer2, data.answer3, data.answer4];
      answers = [data.answer];
    }
    const ques = {
      option_type: data.option_type,
      title: data.question,
      options_meta: options_meta,
      answers: answers,
      group_id: data.group_id,
      feedback: data.feedback,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      image_name: data.image,
      mark: data.mark,
      audio_name: data.audio,
    };

    if (
      !data.option_type ||
      (data.option_type &&
        (data.option_type === "text" || data.option_type === "free_text"))
    ) {
      const url =
        data.image_name && (await uploadFileToS3(data.image_name, uuidv4()));
      const audioUrl =
        data.audio_name && (await uploadFileToS3(data.audio_name, uuidv4()));
      const querySnapshot = await db
        .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
        .doc(ownProps.match.params.id)
        .collection(process.env.REACT_APP_MODEL_QUESTIONS)
        .add({
          ...ques,
          audio: audioUrl,
          options: ques.options_meta,
          image: url,
        });
      const dat = {
        ...ques,
        id: querySnapshot.id,
        audio: audioUrl,
        image: url,
      };
      dispatch(add_model_question(dat));
      dispatch(getModelQuestions(ownProps.match.params.id));
      dispatch(success());
    } else if (
      data.option_type &&
      (data.option_type === "image" || data.option_type === "audio")
    ) {
      const ans1 = await uploadFileToS3(data.answer1.image_name, uuidv4());
      const ans2 = await uploadFileToS3(data.answer2.image_name, uuidv4());
      const ans3 = await uploadFileToS3(data.answer3.image_name, uuidv4());
      const ans4 = await uploadFileToS3(data.answer4.image_name, uuidv4());
      const image =
        data.image && (await uploadFileToS3(data.image_name, uuidv4()));
      const audio =
        data.audio && (await uploadFileToS3(data.audio_name, uuidv4()));
      ques.options_meta[0].image = ans1;
      ques.options_meta[1].image = ans2;
      ques.options_meta[2].image = ans3;
      ques.options_meta[3].image = ans4;

      const querySnapshot = await db
        .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
        .doc(ownProps.match.params.id)
        .collection(process.env.REACT_APP_MODEL_QUESTIONS)
        .add({
          ...ques,
          options: ques.options_meta.map((data) => data.image),
          image: image,
          audio: audio,
          answers: ques.options_meta
            .filter((i) => i.image_name === data.answer)
            .map((i) => i.image),
        });
      const dat = {
        ...ques,
        id: querySnapshot.id,
        image: image,
        audio: audio,
      };
      dispatch(add_model_question(dat));
      dispatch(getModelQuestions(ownProps.match.params.id));
      dispatch(success());
    }
  } catch (err) {
    console.log(err);
    dispatch(error());
  }
};

export const getModelQuestions = (id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(id)
    .collection(process.env.REACT_APP_MODEL_QUESTIONS)
    .orderBy("group_id", "asc")
    .get()
    .then((data) => {
      const questions = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      dispatch(modelsetQuestions(questions));
    })
    .catch((err) => {
      console.error({ err });
    });
};

export const deleteModelQuestion = (deleteData, ownProps) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(ownProps.match.params.id)
    .collection(process.env.REACT_APP_MODEL_QUESTIONS)
    .doc(deleteData.id)
    .delete()
    .then((res) => {
      dispatch(getModelQuestions(ownProps.match.params.id));
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const updateModelQuestion =
  (data, editData, ownProps) => async (dispatch) => {
    dispatch(start());
    const ques = {
      title: data.question,
      group_id: data.group_id,
      image_name: !data.image_cleared
        ? data.image
          ? data.image
          : editData.image_name
        : "",
      audio_name: !data.audio_cleared
        ? data.audio
          ? data.audio
          : editData.audio_name
        : "",
      options_meta:
        editData.option_type === "image" || editData.option_type === "audio"
          ? editData.options_meta
          : [data.answer1, data.answer2, data.answer3, data.answer4],
      options:
        editData.option_type === "image" || editData.option_type === "audio"
          ? editData.options
          : [data.answer1, data.answer2, data.answer3, data.answer4],
      answers: [data.answer],
      feedback: data.feedback,
      mark: data.mark || null,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (data.option_type !== editData.option_type) {
      if (data.option_type === "text") {
        ques.options = [data.answer1, data.answer2, data.answer3, data.answer4];
        ques.options_meta = [
          data.answer1,
          data.answer2,
          data.answer3,
          data.answer4,
        ];
      }
      ques.option_type = data.option_type;
    }

    if (data.image_cleared) {
      ques.image = "";
      ques.image_name = "";
    }

    if (data.audio_cleared) {
      ques.audio = "";
      ques.audio_name = "";
    }

    try {
      if (data.image !== editData.image && !data.image_cleared) {
        ques.image = await uploadFileToS3(
          data.image_name,
          getFileName(editData.image) || uuidv4()
        );
      }
      if (data.audio !== editData.audio && !data.image_cleared) {
        ques.audio = await uploadFileToS3(
          data.audio_name,
          getFileName(editData.audio) || uuidv4()
        );
      }

      if (data.option_type === "image" || data.option_type === "audio") {
        if (typeof data.answer1 === "object") {
          const ans1 = await uploadFileToS3(data.answer1.image_name, uuidv4());
          console.log(ans1);
          ques.options_meta[0].image = ans1;
          ques.options_meta[0].image_name = data.answer1.image;
        }
        if (typeof data.answer2 === "object") {
          const ans2 = await uploadFileToS3(data.answer2.image_name, uuidv4());
          ques.options_meta[1].image = ans2;
          ques.options_meta[1].image_name = data.answer2.image;
        }
        if (typeof data.answer3 === "object") {
          const ans3 = await uploadFileToS3(data.answer3.image_name, uuidv4());
          ques.options_meta[2].image = ans3;
          ques.options_meta[2].image_name = data.answer3.image;
        }
        if (typeof data.answer4 === "object") {
          const ans4 = await uploadFileToS3(data.answer4.image_name, uuidv4());
          ques.options_meta[3].image = ans4;
          ques.options_meta[3].image_name = data.answer3.image;
        }

        ques.answers = ques.options_meta
          .filter(
            (i) => i.image_name === data.answer || i.image === data.answer
          )
          .map((i) => i.image);
        ques.options = ques.options_meta.map((data) => data.image);
      }

      await db
        .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
        .doc(ownProps.match.params.id)
        .collection(process.env.REACT_APP_MODEL_QUESTIONS)
        .doc(editData.id)
        .update({
          ...ques,
          image_name: ques.image_name || "",
          audio_name: ques.audio_name || "",
        });
      dispatch(getModelQuestions(ownProps.match.params.id));
      dispatch(success());
    } catch (err) {
      console.log(err);
      dispatch(error());
    }
  };

export const getModelScore = (setId) => async (dispatch) => {
  try {
    dispatch(start());
    const { docs } = await db
      .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
      .doc(setId)
      .collection("users")
      .get();
    const scores = docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
    dispatch(getScore(scores));
    dispatch(success());
  } catch (error) {
    console.log(error);
    dispatch(error());
  }
};

export const uploadQuestionsFromFile = (file, setId) => async (dispatch) => {
  try {
    dispatch(start());
    const result = await convertExcelToJson(file);
    const questions = result.map((ques, index) => {
      return {
        title: ques.title || "",
        mark: ques.mark || 1,
        group_id: ques.group_id,
        options: [ques.option1, ques.option2, ques.option3, ques.answer],
        options_meta: [ques.option1, ques.option2, ques.option3, ques.answer],
        answers: [ques.answer],
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        order: index + 1,
      };
    });
    await Promise.all(
      questions.map((res) =>
        db
          .collection("model_sets")
          .doc(setId)
          .collection("model_questions")
          .add(res)
      )
    );
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const duplicateSet = (setId) => async (dispatch) => {
  try {
    dispatch(start);
    const setRef = db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION);
    const setSnap = await setRef.doc(setId).get();
    const set = setSnap.data();
    set.title = set.title.concat("(COPY)");
    set.created_by = "pFMERainiLW4TnTExhl5RosXbY73";
    set.emails = [];
    set.institute_id = "r7m8dMoMVMmn1CMydQDP";
    set.institute = {
      value: "r7m8dMoMVMmn1CMydQDP",
      label: "EPS TOPIK PRACTICE",
    };
    set.status = "Draft";
    set.parent_id = setSnap.id;

    const addedSet = await setRef.add(set);
    const { docs } = await setRef
      .doc(setId)
      .collection("model_questions")
      .get();

    const promises = docs.map((doc) => {
      const data = doc.data();
      return setRef.doc(addedSet.id).collection("model_questions").add(data);
    });

    await Promise.all(promises);

    await setRef.doc(setId).update({
      children: firebase.firestore.FieldValue.arrayUnion(addedSet.id),
    });

    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getSelectedScoreReview = (setId, scoreId) => async (dispatch) => {
  try {
    // dispatch(start())
    dispatch(setScoreReview([]));

    const doc = await db
      .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
      .doc(setId)
      .collection("users")
      .doc(scoreId)
      .get();
    const scoreData = doc.data();
    if (scoreData) {
      const reviews = [];
      const answers = scoreData.answers;
      const questionIds = Object.keys(answers);
      const answersArray = Object.values(answers);
      const questionPromises = questionIds.map((questionId) => {
        return db
          .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
          .doc(setId)
          .collection("model_questions")
          .doc(questionId)
          .get();
      });

      const questionSnaps = await Promise.all(questionPromises);
      questionSnaps.forEach((snap, i) => {
        const questionData = snap.data();
        questionData.expectedAnswer = questionData.answers[0];
        questionData.actualAnswer = answersArray[i];
        questionData.isCorrect = questionData.answers.includes(answersArray[i]);
        reviews.push(questionData);
      });
      dispatch(setScoreReview(reviews));
      dispatch(success());
    }
  } catch (error) {
    console.log(error);
    dispatch(error());
  }
};

export const searchModelSetByInstituteName = async (title) => {
  if (!title || title?.length === 0) return [];

  try {
    const ref = db
      .collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
      .where("title", ">=", title)
      .where("title", "<=", title + "\uf8ff")
      .limit(200);
    const snapshot = await ref.get();

    if (!snapshot.empty) {
      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

export const getModelSetById = (parent_id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_MODEL_SET_COLLECTION)
    .doc(parent_id)
    .get()
    .then((querySnapshot) => {
      dispatch(fetchModelSetById({ ...querySnapshot.data(), id: parent_id }));
    })
    .catch((err) => {
      dispatch(error());
    });
};
