import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { closePreview } from "../actions/helper";
import ReactAudioPlayer from "react-audio-player";

export default function Preview() {
  const helper = useSelector((state) => state.helper);
  const dispatch = useDispatch();
  const showPreview = () => {
    if (helper.previewSourceType === "audio") {
      return (
        <ReactAudioPlayer
          style={{ width: "100%" }}
          src={helper.url}
          autoPlay
          controls
        />
      );
    } else if (helper.previewSourceType === "video") {

      return (
        <div>
          <video controls style={{height: "80%", width: "100%"}}>
          <source src={helper.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
      );
    } else {
      return <img src={helper.url} alt="" style={{ width: "100%" }} />;
    }
  };
  return (
    <Modal
      style={{ width: "100%", height: "100%" }}
      isOpen={helper.openPreview}
      toggle={() => dispatch(closePreview())}
    >
      <ModalHeader toggle={() => dispatch(closePreview())}></ModalHeader>
      <ModalBody>{showPreview()}</ModalBody>
    </Modal>
  );
}
