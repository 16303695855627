import { db } from "../firebase/firebase";
import firebase from "firebase";
import {
  deleteFileFromS3,
  uploadInstituteResource,
} from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";
import { VIDEO_URL } from "../urls/server";
import { backend } from "../config/network";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ADD_VIDEO = "ADD_VIDEO";
export const FETCH_VIDEO = "FETCH_VIDEO";

export const FETCH_ALL_VIDEOS = "FETCH_ALL_VIDEOS";
export const FETCH_VIDEOS_BY_PLAYLIST_ID = "FETCH_VIDEOS_BY_PLAYLIST_ID";
export const DELETE_VIDEOS = "DELETE_VIDEOS";
export const CREATE_VIDEOS = "CREATE_VIDEOS";
export const UPDATE_VIDEOS = "UPDATE_VIDEOS";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};
const fetchVideos = (video) => {
  return {
    type: FETCH_VIDEO,
    payload: video,
  };
};

export const addVideo = (thisVideo, cb) => async (dispatch) => {
  dispatch(start());
  let thumbNailUrl = "";
  let videoUrl = "";
  try {
    if (thisVideo.thumbnail) {
      thumbNailUrl = await uploadInstituteResource(
        thisVideo.thumbnail,
        uuidv4(),
        thisVideo.institute_id,
        "video",
      );
    }
    if (thisVideo.video) {
      videoUrl = await uploadInstituteResource(
        thisVideo.video,
        uuidv4(),
        thisVideo.institute_id,
        "video",
        cb,
      );
    }

    const {
      title,
      status,
      modelSetId,
      description,
      playlistIds,
      language,
      expiresIn,
      type,
      youtubeURL,
    } = thisVideo;

    const newDocRef = db.collection("videos").doc();
    await newDocRef.set({
      title,
      status,
      modelSetId,
      description,
      playlistIds,
      language,
      id: newDocRef.id,
      videoURL: videoUrl,
      type,
      thumbnailURL: thumbNailUrl,
      expiresIn: parseInt(expiresIn),
      createdAt: firebase.firestore.Timestamp.now().toMillis(),
      updatedAt: null,
      youtubeURL,
    });
    await db
      .collection("playlists")
      .doc(playlistIds[0])
      .update({
        videoIds: firebase.firestore.FieldValue.arrayUnion(newDocRef.id),
      });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getVideoDetail = (id) => (dispatch) => {
  dispatch(start());
  db.collection("videos")
    .doc(id)
    .get()
    .then((querySnapshot) => {
      dispatch(updateVideo(querySnapshot.data()));
    });
};

export const updateVideo = (data) => async (dispatch) => {
  dispatch(start());
  let thumbNailurl = data.thumbnail;
  try {
    if (data.thumbnail_preview && data.thumbnail) {
      thumbNailurl = await uploadInstituteResource(
        data.thumbnail,
        uuidv4(),
        data.institute_id,
        "video",
      );
    }
    const {
      title,
      description,
      playlistIds,
      oldPlaylistIds,
      modelSetId,
      expiresIn,
      type,
      youtubeURL,
    } = data;

    let updatedFields = {};
    if (title) updatedFields["title"] = title;
    if (description) updatedFields["description"] = description;
    if (playlistIds) updatedFields["playlistIds"] = playlistIds;
    if (thumbNailurl) updatedFields["thumbnailURL"] = thumbNailurl;
    if (modelSetId) updatedFields["modelSetId"] = modelSetId;
    if (expiresIn) updatedFields["expiresIn"] = parseInt(expiresIn);
    if (type) updatedFields["type"] = type;

    await db
      .collection("playlists")
      .doc(oldPlaylistIds[0])
      .update({
        videoIds: firebase.firestore.FieldValue.arrayRemove(data.id),
      });

    let updateData = {
      ...updatedFields,
      youtubeURL,
      //price: data.type == "free" ? "" : data.price,
      last_updated: firebase.firestore.Timestamp.now().toMillis(),
    };

    const response = await db
      .collection("videos")
      .doc(data.id)
      .update({ ...updateData });

    await db
      .collection("playlists")
      .doc(playlistIds[0])
      .update({
        videoIds: firebase.firestore.FieldValue.arrayUnion(data.id),
      });
    dispatch(getVideoDetail(data.id));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getAllVideos = (videoParam) => (dispatch) => {
  const {
    institute_id,
    playlist_id,
    storeRefOfVideoDoc,
    lastRefOfVideoDoc,
    limitTofetch = 100,
    videos = [],
  } = videoParam ?? {};
  dispatch(start());
  let videoRef = db.collection("videos");

  if (playlist_id) {
    videoRef
      .where("institute_id", "==", institute_id)
      .where("playlist_id", "==", playlist_id)
      .onSnapshot((querySnapshot) => {
        const responseData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(fetchVideos(responseData));
        dispatch(success());
      });
  } else if (institute_id && !playlist_id) {
    videoRef
      .where("institute_id", "==", institute_id)
      .onSnapshot((querySnapshot) => {
        const responseData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(fetchVideos(responseData));
        dispatch(success());
      });
  } else {
    videoRef = videoRef.limit(limitTofetch);

    if (lastRefOfVideoDoc) {
      videoRef = videoRef.startAfter(lastRefOfVideoDoc);
    }

    videoRef.onSnapshot((querySnapshot) => {
      const responseData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      if (storeRefOfVideoDoc) {
        storeRefOfVideoDoc(querySnapshot.docs[limitTofetch - 1]);
      }
      dispatch(
        fetchVideos(
          [...videos, ...responseData].sort(
            (a, b) => a.createdAt - b.createdAt,
          ),
        ),
      );
      dispatch(success());
    });
  }
};

export const changeVideoStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection("videos")
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const deleteVideo = (playlistId, video) => async (dispatch) => {
  const { id, thumbnailURL, videoURL } = video;

  const thumbnailFile = thumbnailURL.split("/");
  const thumbnailFileName = thumbnailFile[thumbnailFile.length - 1];

  const videoFile = videoURL.split("/");
  const videoFileName = videoFile[videoFile.length - 1];
  dispatch(start());
  try {
    await deleteFileFromS3(
      `${process.env.REACT_APP_STORAGE}/video/${thumbnailFileName}`,
    );
    await deleteFileFromS3(
      `${process.env.REACT_APP_STORAGE}/video/${videoFileName}`,
    );
  } catch (error) {
    console.log(error);
  }
  db.collection("playlists")
    .doc(playlistId)
    .update({
      videoIds: firebase.firestore.FieldValue.arrayRemove(id),
    })
    .catch((err) => dispatch(error()));

  db.collection("videos")
    .doc(id)
    .delete()
    .then((data) => {
      dispatch(success());
      dispatch(getAllVideos());
    })
    .catch((err) => dispatch(error()));
};

export const filterByInstituteAndPlaylist =
  (institute, playlist) => (dispatch) => {
    dispatch(start());
    if (!playlist) {
      db.collection("videos")
        .where("institute_id", "==", "lZNhoe0SeGIeK7FSeGrk")

        .orderBy("created_at")
        .onSnapshot((querySnapshot) => {
          const responseData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          dispatch(fetchVideos(responseData));
          dispatch(success());
        });
    } else {
      db.collection("videos")
        .where("institute_id", "==", "lZNhoe0SeGIeK7FSeGrk")
        .orderBy("created_at")
        .onSnapshot((querySnapshot) => {
          const responseData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          dispatch(fetchVideos(responseData));
          dispatch(success());
        });
    }
  };

// api

const storeAllFetchedVideos = (playlists) => {
  return {
    type: FETCH_ALL_VIDEOS,
    payload: playlists,
  };
};

const storeFetchedVideosByPlayListId = (playlists) => {
  return {
    type: FETCH_VIDEOS_BY_PLAYLIST_ID,
    payload: playlists,
  };
};
const deleteNewVideo = (ids) => {
  return {
    type: DELETE_VIDEOS,
    payload: ids,
  };
};

const createVideos = (playlist) => {
  return {
    type: CREATE_VIDEOS,
    payload: playlist,
  };
};

const updateNewVideos = (playlist) => {
  return {
    type: UPDATE_VIDEOS,
    payload: playlist,
  };
};

export const createVideo = (video) => async (dispatch) => {
  dispatch(start());

  try {
    const formData = new FormData();

    for (const data in video) {
      formData.append(data, video[data]);
    }
    const response = await backend.post(VIDEO_URL, formData, {
      timeout: 900000,
    });

    dispatch(createVideos(response.data.data));
    dispatch(success());
  } catch (err) {
    let errorMessage = err;
    dispatch(error());
    if (errorMessage.code === "ECONNABORTED") {
      errorMessage = "Video is too large.Try after compressing.";
    }
    throw new Error(errorMessage);
  }
};

export const updateVideoById =
  ({ video, id }) =>
  async (dispatch) => {
    dispatch(start());

    try {
      const formData = new FormData();

      for (const data in video) {
        const value = video[data];
        if (value !== null) {
          formData.append(data, value);
        }
      }
      const response = await backend.patch(`${VIDEO_URL}/${id}`, formData);
      dispatch(updateNewVideos(response.data.data));
      dispatch(success());
    } catch (err) {
      console.log(err);
      dispatch(error());
    }
  };

export const fetchAllVideos =
  ({ limit, page, playlistId }) =>
  async (dispatch) => {
    dispatch(start());

    let params = {
      limit,
      page,
    };

    if (playlistId) {
      params.playlistId = playlistId;
    }

    try {
      const videos = await backend.get(VIDEO_URL, {
        params,
      });

      if (playlistId) {
        dispatch(
          storeFetchedVideosByPlayListId({
            playlistId,
            videos: videos.data.data,
          }),
        );
      } else {
        dispatch(storeAllFetchedVideos(videos.data.data));
      }

      dispatch(success());
    } catch (err) {
      console.log(err);
      dispatch(error());
    }
  };

export const deleteVideoById = (playlistId, id) => async (dispatch) => {
  dispatch(start());

  try {
    await backend.delete(`${VIDEO_URL}/${id}`);
    dispatch(deleteNewVideo({ playlistId, videoId: id }));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};
