import {
  START,
  SUCCESS,
  ERROR,
  FETCH_PLAYLIST,
  FETCH_UPDATED,
  FETCH_ALL_PLAYLISTS,
  DELETE_PLAYLIST,
  CREATE_PLAYLIST,
  UPDATE_PLAYLIST,
} from "../actions/playlists";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    playlists: [],
    newPlayLists: {},
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_PLAYLIST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        playlists: action.payload,
      };
    case FETCH_ALL_PLAYLISTS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newPlayLists: action.payload,
      };
    case DELETE_PLAYLIST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newPlayLists: {
          ...state.newPlayLists,
          items: state.newPlayLists.items.filter(
            ({ _id }) => _id !== action.payload
          ),
        },
      };

    case CREATE_PLAYLIST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newPlayLists: {
          ...state.newPlayLists,
          total: state.newPlayLists.total + 1,
          items: [...state.newPlayLists.items, action.payload],
        },
      };
    case UPDATE_PLAYLIST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newPlayLists: {
          ...state.newPlayLists,
          total: state.newPlayLists.total + 1,
          items: [...state.newPlayLists.items].map((item) => {
            if (item._id === action.payload._id) {
              return action.payload;
            } else {
              return item;
            }
          }),
        },
      };
    case FETCH_UPDATED:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        playlists: [
          action.payload,
          ...state.playlists.filter((each) => each.id != action.payload.id),
        ],
      };

    default:
      return state;
  }
};
