import {
  START,
  SUCCESS,
  ERROR,
  GET_ONE_PACKAGE,
  FETCH_PACKAGES_LIST,
  SELECT_INSTITUTE,
  GET_SET_BY_INSTITUTE,
  FETCH_PACKAGE_SUBSCRIPTIONS,
  FETCH_PACKAGE_SUBSCRIPTIONS_BY_USERID,
  FETCH_PACKAGE_SUBSCRIPTIONS_BY_ID,
  FETCH_PACKAGES_BY_INSTITUTE_ID,
} from "../actions/set_packaging";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    packages: [],
    package: {},
    institute: {},
    instituteSets: [],
    packageSubscriptions: [],
    packageSubscriptionsByUserId: [],
    packageSubscriptionsById: [],
    packagesByInstituteId: {},
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };

    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_PACKAGES_LIST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        packages: action.payload,
      };
    case FETCH_PACKAGE_SUBSCRIPTIONS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        packageSubscriptions: action.payload,
      };
    case FETCH_PACKAGES_BY_INSTITUTE_ID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        packagesByInstituteId: {
          ...state.packagesByInstituteId,
          [action.payload.instituteId]: action.payload.packages,
        },
      };
    case FETCH_PACKAGE_SUBSCRIPTIONS_BY_USERID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        packageSubscriptionsByUserId: action.payload,
      };
    case FETCH_PACKAGE_SUBSCRIPTIONS_BY_ID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        [action.payload.id]: action.payload.packageSubscriptionsById,
      };
    case GET_ONE_PACKAGE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        package: action.payload,
      };
    case SELECT_INSTITUTE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        institute: action.payload,
      };
    case GET_SET_BY_INSTITUTE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        instituteSets: action.payload,
      };

    default:
      return state;
  }
};
