import { db } from "../firebase/firebase";
import firebase from "firebase";
import {
  getFileName,
  uploadFileToS3,
  uploadInstituteResource,
} from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";
import { backend } from "../config/network";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ADD_PLAYLIST = "ADD_PLAYLIST";
export const FETCH_PLAYLIST = "FETCH_PLAYLIST";

// new api playlist

export const FETCH_ALL_PLAYLISTS = "FETCH_ALL_PLAYLISTS";
export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const CREATE_PLAYLIST = "CREATE_PLAYLIST";
export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";

export const FETCH_UPDATED = "FETCH_UPDATED";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};
const fetchPlaylists = (playlists) => {
  return {
    type: FETCH_PLAYLIST,
    payload: playlists,
  };
};

const storeAllFetchedPlaylists = (playlists) => {
  return {
    type: FETCH_ALL_PLAYLISTS,
    payload: playlists,
  };
};

const deletePlaylists = (id) => {
  return {
    type: DELETE_PLAYLIST,
    payload: id,
  };
};

const createPlaylists = (playlist) => {
  return {
    type: CREATE_PLAYLIST,
    payload: playlist,
  };
};

const updateNewPlaylist = (playlist) => {
  return {
    type: UPDATE_PLAYLIST,
    payload: playlist,
  };
};

const updatedPlaylist = (playlist) => {
  return {
    type: FETCH_UPDATED,
    payload: playlist,
  };
};

export const addPlaylist = (playlist) => async (dispatch) => {
  dispatch(start());
  const thumbNailurl = await uploadInstituteResource(
    playlist.thumbnail,
    uuidv4(),
    playlist.instituteId,
    "playlist"
  );

  try {
    const {
      name,
      description,
      status,
      type,
      subscriptionIds,
      videoIds,
      percentDiscount,
      flatDiscount,
      price,
      instituteId,
      expiresIn,
      ubtPackageIds,
    } = playlist;
    const newDocRef = db.collection("playlists").doc();
    await newDocRef.set({
      name,
      description,
      status,
      type,
      subscriptionIds,
      videoIds,
      percentDiscount: parseInt(percentDiscount),
      flatDiscount: parseInt(flatDiscount),
      price: parseInt(price),
      instituteId,
      id: newDocRef.id,
      thumbnailURL: thumbNailurl,
      expiresIn: parseInt(expiresIn),
      createdAt: firebase.firestore.Timestamp.now().toMillis(),
      updatedAt: null,
      ubtPackageIds,
    });
    dispatch(success());
  } catch (err) {
    console.log(err);
    dispatch(error());
  }
};

export const getAllPlaylists = (playlistParam) => (dispatch) => {
  const {
    storeRefOfDoc,
    lastRefOfDoc,
    limitTofetch,
    playlists = [],
  } = playlistParam ?? {};
  dispatch(start());

  let playlistRef = db.collection("playlists");
  if (limitTofetch) {
    playlistRef = playlistRef.limit(limitTofetch);
  }
  // .orderBy("createdAt");

  if (lastRefOfDoc) {
    playlistRef = playlistRef.startAfter(lastRefOfDoc);
  }

  playlistRef.onSnapshot((querySnapshot) => {
    if (storeRefOfDoc) {
      storeRefOfDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
    const responseData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    dispatch(fetchPlaylists([...playlists, ...responseData]));
    dispatch(success());
  });
};

export const getPlaylistDetail = (id) => (dispatch) => {
  dispatch(start());
  db.collection("playlists")
    .doc(id)
    .get()
    .then((querySnapshot) => {
      dispatch(updatedPlaylist(querySnapshot.data()));
    });
};

export const updatePlayList = (data) => async (dispatch) => {
  dispatch(start());
  let thumbNailurl = data.thumbnailURL;
  try {
    if (data.thumbnail_preview && data.thumbnail) {
      thumbNailurl = await uploadInstituteResource(
        data.thumbnail,
        uuidv4(),
        data.instituteId,
        "playlist"
      );
    }
    const {
      name,
      description,
      status,
      type,
      subscriptionIds,
      videoIds,
      percentDiscount,
      flatDiscount,
      instituteId,
      expiresIn,
      ubtPackageIds,
    } = data;

    let updateData = {
      name,
      description,
      status,
      type,
      subscriptionIds,
      videoIds,
      percentDiscount: isNaN(parseInt(percentDiscount))
        ? 0
        : parseInt(percentDiscount),
      flatDiscount: isNaN(parseInt(flatDiscount)) ? 0 : parseInt(flatDiscount),
      instituteId,
      price: data.type === "free" ? 0 : parseInt(data.price),
      thumbnailURL: thumbNailurl,
      expiresIn: parseInt(expiresIn),
      updatedAt: firebase.firestore.Timestamp.now().toMillis(),
      ubtPackageIds,
    };

    await db
      .collection("playlists")
      .doc(data.id)
      .update({ ...updateData });
    dispatch(getPlaylistDetail(data.id));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const changePlaylistStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection("playlists")
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const getFilteredPlaylist = (institute_id) => (dispatch) => {
  dispatch(start());
  if (institute_id) {
    db.collection("playlists")
      .where("instituteId", "==", institute_id)
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        const responseData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(fetchPlaylists(responseData));
        dispatch(success());
      });
  } else {
    db.collection("playlists")
      .where("instituteId", "==", null)
      .orderBy("createdAt")
      .onSnapshot((querySnapshot) => {
        const responseData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        dispatch(fetchPlaylists(responseData));
        dispatch(success());
      });
  }
};

// api

export const createPlaylist = (playlist) => async (dispatch) => {
  dispatch(start());

  try {
    const formData = new FormData();

    for (const data in playlist) {
      formData.append(data, playlist[data]);
    }
    const response = await backend.post("playlist", formData);

    dispatch(createPlaylists(response.data.data));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const updatePlaylistById =
  ({ playlist, id }) =>
  async (dispatch) => {
    dispatch(start());

    try {
      const formData = new FormData();

      for (const data in playlist) {
        const value = playlist[data];
        if (value !== null) {
          formData.append(data, value);
        }
      }
      const response = await backend.patch(`playlist/${id}`, formData);
      dispatch(updateNewPlaylist(response.data.data));
      dispatch(success());
    } catch (err) {
      dispatch(error());
    }
  };

export const fetchAllPlaylists =
  ({ limit, page }) =>
  async (dispatch) => {
    dispatch(start());

    try {
      const playLists = await backend.get("playlist", {
        params: {
          limit,
          page,
        },
      });

      dispatch(storeAllFetchedPlaylists(playLists.data.data));
      dispatch(success());
    } catch (err) {
      dispatch(error());
    }
  };

export const deletePlaylistById = (id) => async (dispatch) => {
  dispatch(start());

  try {
    await backend.delete(`playlist/${id}`);
    dispatch(deletePlaylists(id));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};
