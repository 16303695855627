import {
  START,
  SUCCESS,
  ERROR,
  FETCH_LESSONS,
  FETCH_SUB_UNITS,
  FETCH_UNITS,
} from "../actions/units";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    lessons: [],
    units: [],
    subunits: [],
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_UNITS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        units: action.payload,
      };
    case FETCH_SUB_UNITS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        subunits: action.payload,
      };
    case FETCH_LESSONS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        lessons: action.payload,
      };
    default:
      return state;
  }
};
