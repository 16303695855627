import {
  PARTNERSHIP_REQUEST_START,
  PARTNERSHIP_REQUEST_ERROR,
  FETCH_PARTNERSHIP_REQUEST,
} from "../actions/partnershipRequest";

const initialState = {
  started: false,
  success: false,
  error: false,
  loading: false,
  partnerShipRequests: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PARTNERSHIP_REQUEST_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };

    case PARTNERSHIP_REQUEST_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };

    case FETCH_PARTNERSHIP_REQUEST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        partnerShipRequests: action.payload,
      };

    default:
      return state;
  }
};
