import { db, storage } from "../firebase/firebase";
import firebase from "firebase";
import { getFileName, uploadFileToS3 } from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";

export const SET_ADD_START = "SET_ADD_START";
export const SET_ADD_SUCCESS = "SET_ADD_SUCCESS";
export const SET_ADD_ERROR = "SET_ADD_ERROR";
export const SET_UPDATE_START = "SET_UPDATE_START";
export const SET_UPDATE_SUCCESS = "SET_UPDATE_SUCCESS";
export const SET_UPDATE_ERROR = "SET_UPDATE_ERROR";
export const SET_DELETE_START = "SET_DELETE_START";
export const SET_DELETE_SUCCESS = "SET_DELETE_SUCCESS";
export const SET_DELETE_ERROR = "SET_DELETE_ERROR";
export const FETCH_SET = "FETCH_SET";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const ADD_QUESTION = "ADD_QUESTION";
export const PRACTICE_DETAIL = "PRACTICE_DETAIL";
export const FETCH_PRACTISE_SET_WITH_PAGINATION =
  "FETCH_PRACTISE_SET_WITH_PAGINATION";

const start = () => {
  return {
    type: SET_ADD_START,
  };
};
const success = (brand) => {
  return {
    type: SET_ADD_SUCCESS,
    brand,
  };
};
const error = () => {
  return {
    type: SET_ADD_ERROR,
  };
};

const fetchSet = (sets) => {
  return {
    type: FETCH_SET,
    sets,
  };
};

const fetPractiseSetWithPage = (sets) => {
  return {
    type: FETCH_PRACTISE_SET_WITH_PAGINATION,
    practiseSetsWithPage: sets,
  };
};

const setQuestion = (questions) => {
  return {
    type: GET_QUESTIONS,
    questions,
  };
};

const add_question = (question) => {
  return {
    type: ADD_QUESTION,
    question,
  };
};

const getPracticeDetail = (detail) => {
  return {
    type: PRACTICE_DETAIL,
    practiceDetail: detail,
  };
};

const uploadImage = (image_name, image, collection) => {
  return image
    ? new Promise((resolve, reject) => {
        const uploadImage = storage
          .ref(`${collection}/${image_name}`)
          .put(image);
        uploadImage.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log(progress);
          },
          (error) => {
            console.log(error);
          },
          () => {
            storage
              .ref(collection)
              .child(image_name)
              .getDownloadURL()
              .then((url) => {
                resolve(url);
              });
          }
        );
      })
    : new Promise((resolve, reject) => resolve(null));
};

const deleteImage = (image_name, collection) => {
  return image_name
    ? new Promise((resolve, reject) => {
        storage
          .ref(collection)
          .child(image_name)
          .delete()
          .then((data) => {
            return resolve(data);
          });
      })
    : new Promise((resolve, reject) => resolve("No image"));
};

export const fetchSets = () => async (dispatch) => {
  let setDoc = db
    .collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .orderBy("createdAt", "desc");

  try {
    const querySnapshot = await setDoc.get();
    const sets = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    dispatch(fetchSet(sets));
    dispatch(success());
  } catch (err) {
    dispatch(error());
    console.error(err);
  }
};

export const fetchPaginationSets = (practiseSetsParam) => async (dispatch) => {
  dispatch(start());
  const {
    storeLastIndexRefOfDoc,
    lastIndexRefOfDoc,
    order = "asc",
    limit: limitCount,
    oldPractiseSetWithPage = [],
    setStopFetching,
  } = practiseSetsParam ?? {};
  let setDoc = db
    .collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .orderBy("createdAt", "desc");
  // if (limitCount) {
  //   setDoc = setDoc.limit(limitCount);
  // }
  if (lastIndexRefOfDoc !== undefined) {
    setDoc = setDoc.startAfter(lastIndexRefOfDoc);
  }

  try {
    const querySnapshot = await setDoc.get();

    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    if (storeLastIndexRefOfDoc) {
      storeLastIndexRefOfDoc(lastVisible);
    }

    const sets = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    setStopFetching((sets?.length ?? 0) === 0);
    dispatch(fetPractiseSetWithPage([...oldPractiseSetWithPage, ...sets]));
    dispatch(success());
  } catch (err) {
    dispatch(error());
    console.error(err);
  }
};

export const addSet = (set, ownProps, sets) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .add({
      ...set,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((querySnapshot) => {
      dispatch(
        fetPractiseSetWithPage([{ ...set, id: querySnapshot.id }, ...sets])
      );

      dispatch(success({ ...set, id: querySnapshot.id }));
      ownProps.history.push("/practiceset/list/" + querySnapshot.id);
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const editSet = (data, id, cb) => (dispatch) => {
  dispatch(start());
  const updateData = {
    title: data.title,
    level: data.level,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(id)
    .update(updateData)
    .then((data) => {
      cb({ ...updateData, id });
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const changeStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(fetchSets());
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const deleteSet = (set, practiseSets) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(set[0])
    .collection(process.env.REACT_APP_PRACTICE_QUESTIONS)
    .get()
    .then((data) => {
      if (data.docs.length > 0) {
        data.docs.map((el) => {
          db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
            .doc(set[0])
            .collection(process.env.REACT_APP_PRACTICE_QUESTIONS)
            .doc(el.id)
            .delete()
            .then(() => {
              db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
                .doc(set[0])
                .delete()
                .then(() => {
                  dispatch(fetchSets());
                });
            });
        });
      } else {
        db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
          .doc(set[0])
          .delete()
          .then(() => {
            dispatch(fetchSets());
          });
      }
      const practise_sets = practiseSets.filter((practiseSet) => {
        return practiseSet.id !== set[0];
      });

      dispatch(fetPractiseSetWithPage([...practise_sets]));
    })
    .catch((err) => alert(err));
};

export const getQuestions = (id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(id)
    .collection(process.env.REACT_APP_PRACTICE_QUESTIONS)
    .orderBy("createdAt", "asc")
    .get()
    .then((data) => {
      const questions = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      dispatch(setQuestion(questions));
    });
};

export const addQuestion = (data, bool, ownProps) => async (dispatch) => {
  dispatch(start());
  let options_meta = [];
  let answers = [];
  if (data.option_type === "image") {
    options_meta = [
      { image_name: data.answer1.image },
      { image_name: data.answer2.image },
      { image_name: data.answer3.image },
      { image_name: data.answer4.image },
    ];
    answers = [{ image_name: data.answer }];
  } else {
    options_meta = [data.answer1, data.answer2, data.answer3, data.answer4];
    answers = [data.answer];
  }
  const ques = {
    option_type: data.option_type,
    title: data.question,
    options_meta: options_meta,
    answers: answers,
    options_ordered: data.options_ordered,
    feedback: data.feedback,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    image_name: data.image,
    audio_name: data.audio,
  };

  try {
    if (
      !data.option_type ||
      (data.option_type && data.option_type === "text")
    ) {
      const url =
        data.image_name && (await uploadFileToS3(data.image_name, uuidv4()));
      const audioUrl =
        data.audio_name && (await uploadFileToS3(data.audio_name, uuidv4()));
      const querySnapshot = await db
        .collection("practice_sets")
        .doc(ownProps.match.params.id)
        .collection("questions")
        .add({
          ...ques,
          audio: audioUrl,
          options: ques.options_meta,
          image: url,
        });
      const dat = {
        ...ques,
        id: querySnapshot.id,
        audio: audioUrl,
        image: url,
      };
      dispatch(add_question(dat));
      dispatch(success());
      bool && ownProps.history.goBack();
    } else if (data.option_type && data.option_type === "image") {
      const ans1 = await uploadFileToS3(data.answer1.image_name, uuidv4());
      const ans2 = await uploadFileToS3(data.answer2.image_name, uuidv4());
      const ans3 = await uploadFileToS3(data.answer3.image_name, uuidv4());
      const ans4 = await uploadFileToS3(data.answer4.image_name, uuidv4());
      const image =
        data.image && (await uploadFileToS3(data.image_name, uuidv4()));
      const audio =
        data.audio && (await uploadFileToS3(data.image_name, uuidv4()));
      ques.options_meta[0].image = ans1;
      ques.options_meta[1].image = ans2;
      ques.options_meta[2].image = ans3;
      ques.options_meta[3].image = ans4;

      const querySnapshot = await db
        .collection("practice_sets")
        .doc(ownProps.match.params.id)
        .collection("questions")
        .add({
          ...ques,
          answers: ques.options_meta
            .filter((i) => i.image_name === data.answer)
            .map((i) => i.image),
          options: ques.options_meta.map((data) => data.image),
          image: image,
          audio: audio,
        });
      const dat = {
        ...ques,
        id: querySnapshot.id,
        image: image,
        audio: audio,
      };
      dispatch(add_question(dat));
      dispatch(success());
      bool && ownProps.history.goBack();
    }
  } catch (err) {
    dispatch(error());
  }
};

export const deleteQuestion = (deleteData, ownProps) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(ownProps.match.params.id)
    .collection(process.env.REACT_APP_PRACTICE_QUESTIONS)
    .doc(deleteData.id)
    .delete()
    .then((res) => {
      dispatch(getQuestions(ownProps.match.params.id));
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const updateQuestion =
  (data, editData, ownProps) => async (dispatch) => {
    dispatch(start());
    const ques = {
      title: data.question,
      image_name: !data.image_cleared
        ? data.image
          ? data.image
          : editData.image_name
        : "",
      audio_name: !data.audio_cleared
        ? data.audio
          ? data.audio
          : editData.audio_name || null
        : "" || null,
      options_meta:
        editData.option_type === "image"
          ? editData.options_meta
          : [data.answer1, data.answer2, data.answer3, data.answer4],
      options:
        editData.option_type === "image"
          ? editData.options
          : [data.answer1, data.answer2, data.answer3, data.answer4],
      answers: [data.answer],
      feedback: data.feedback,
      options_ordered: data.options_ordered || false,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    try {
      if (data.image != "") {
        ques.image = await uploadFileToS3(
          data.image_name,
          getFileName(editData.image) || uuidv4()
        );
      }
      if (data.audio != "") {
        ques.audio = await uploadFileToS3(
          data.audio_name,
          getFileName(editData.audio) || uuidv4()
        );
      }
      if (data.image_cleared) {
        ques.image = null;
      }
      if (data.audio_cleared) {
        ques.audio = null;
      }

      if (data.option_type && data.option_type === "image") {
        if (typeof data.answer1 === "object") {
          const ans1 = await uploadFileToS3(data.answer1.image_name, uuidv4());
          ques.options_meta[0].image = ans1;
          ques.options_meta[0].image_name = data.answer1.image;
        }
        if (typeof data.answer2 === "object") {
          const ans2 = await uploadFileToS3(data.answer2.image_name, uuidv4());
          ques.options_meta[1].image = ans2;
          ques.options_meta[1].image_name = data.answer2.image;
        }
        if (typeof data.answer3 === "object") {
          const ans3 = await uploadFileToS3(data.answer3.image_name, uuidv4());
          ques.options_meta[2].image = ans3;
          ques.options_meta[2].image_name = data.answer3.image;
        }
        if (typeof data.answer4 === "object") {
          const ans4 = await uploadFileToS3(data.answer4.image_name, uuidv4());
          ques.options_meta[3].image = ans4;
          ques.options_meta[3].image_name = data.answer4.image;
        }
        const image =
          data.image && (await uploadFileToS3(data.image_name, uuidv4()));
        const audio =
          data.audio && (await uploadFileToS3(data.image_name, uuidv4()));

        ques.answers = ques.options_meta
          .filter(
            (i) => i.image_name === data.answer || i.image === data.answer
          )
          .map((i) => i.image);
        ques.options = ques.options_meta.map((data) => data.image);
        ques.image = image;
        ques.audio = audio;
      }

      await db
        .collection("practice_sets")
        .doc(ownProps.match.params.id)
        .collection("questions")
        .doc(editData.id)
        .update({ ...ques });
      dispatch(getQuestions(ownProps.match.params.id));
      dispatch(success());
    } catch (err) {
      dispatch(error());
    }
  };

export const practiceDetail = (id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_PRACTICE_SET_COLLECTION)
    .doc(id)
    .get()
    .then((querySnapshot) => {
      dispatch(getPracticeDetail(querySnapshot.data()));
    });
};
