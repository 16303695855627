import {
  START,
  SUCCESS,
  ERROR,
  FETCH_INSTITUTE_LOGINS,
  GET_INSTITUTE,
  FETCH_INSTITUTE_REQUEST,
  PLAYED_MODEL_SET,
} from "../actions/institute";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    instituteLogins: [],
    instituteLogin: {},
    institute: {},
    modelSetPlayedByUser: null,
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };

    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_INSTITUTE_LOGINS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        instituteLogins: action.payload,
      };

    case FETCH_INSTITUTE_REQUEST:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        instituteRequests: action.payload,
      };
    case GET_INSTITUTE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        institute: action.payload,
      };

    case PLAYED_MODEL_SET:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        modelSetPlayedByUser: action.payload.data.data,
      };

    default:
      return state;
  }
};
