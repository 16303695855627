import {
    START,
    SUCCESS,
    ERROR,
    FETCH_PAYMENTS,
  } from "../actions/paymentsV2";
  
  export default (
    state = {
      started: false,
      success: false,
      error: false,
      loading: false,
      payments: [],
    },
    action
  ) => {
    switch (action.type) {
      case START:
        return {
          ...state,
          started: true,
          loading: true,
          success: false,
          error: false,
        };
      case SUCCESS:
        return {
          ...state,
          started: false,
          success: true,
          loading: false,
          error: false,
        };
  
      case ERROR:
        return {
          ...state,
          started: false,
          loading: false,
          success: false,
          error: true,
        };
      case FETCH_PAYMENTS:
        return {
          ...state,
          started: false,
          loading: false,
          success: true,
          error: false,
          payments: action.payload,
        };
  
      default:
        return state;
    }
  };
  