import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import ProtectedRoute from "./containers/ProtectedRoute";
import ResetPassword from "./views/auth/PasswordReset";
import Preview from "./components/Preview";
import { Toaster } from "react-hot-toast";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  render() {
    const { isAuthenticated, isVerifying } = this.props;
    return (
      <>
        <React.Suspense fallback={loading()}>
          <Preview />
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/set-password"
              name="Set Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            {/* <ToastContainer> */}

            <ProtectedRoute
              role={localStorage.getItem("user_role")}
              path="/"
              render={(props) => <DefaultLayout {...props} />}
              component={DefaultLayout}
              isAuthenticated={isAuthenticated}
              isVerifying={isVerifying}
            />
            {/* </ToastContainer> */}
          </Switch>
        </React.Suspense>
        <Toaster />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
  };
};

export default connect(mapStateToProps)(App);
