import { CLOSE_IMAGE_PREVIEW, OPEN_IMAGE_PREVIEW } from "../actions/helper"

const initialState = {
  url: '',
  openPreview: false,
  previewSourceType: ''
}
export default (state=initialState, action) => {
  switch (action.type) {
    case OPEN_IMAGE_PREVIEW:
      return {
        ...state,
        url: action.url,
        openPreview: true,
        previewSourceType: action.previewSourceType
      }
    case CLOSE_IMAGE_PREVIEW:
      return {
        ...state,
        imageLink: '',
        openPreview: false,
        previewSourceType: ''
      }
    default:
      return state;
  }
}
