import {
  START,
  SUCCESS,
  ERROR,
  FETCH_SETTING,
  FETCH_ADS,
  ADD_SETTING,
  FETCH_INSTITUTE
} from "../actions/settings";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    settings: [],
    setting: {},
    ads: []
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false
      };
    case ADD_SETTING:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        setting: action.setting
      };
    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true
      };
    case FETCH_SETTING:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        settings: action.payload
      };
    case FETCH_INSTITUTE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        institutes: action.payload
      };
    case FETCH_ADS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        ads: action.payload
      };
    default:
      return state;
  }
};
