import { db } from "../firebase/firebase";

export const CHECK_USER = "CHECK_USER";
export const TOTAL_USERS = "TOTAL_USERS";

export const USER_START = "USER_START";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";

export const FETCH_USERS_BY_DATE_RANGE = "FETCH_USERS_BY_DATE_RANGE";

const getUsersLength = (totalUserSize) => {
  return {
    type: TOTAL_USERS,
    totalUserSize,
  };
};

const start = () => {
  return {
    type: USER_START,
  };
};
const success = (user) => {
  return {
    type: USER_SUCCESS,
    user,
  };
};
const error = () => {
  return {
    type: USER_ERROR,
  };
};

const fetchUsers = (users) => {
  return {
    type: FETCH_USERS_BY_DATE_RANGE,
    payload: users,
  };
};

const fetchUserById = (id, user) => {
  return {
    type: FETCH_USER_BY_ID,
    payload: {
      id,
      user,
    },
  };
};

export const getUserDetailByEmails = async (emails) => {
  const query = await db.collection("users").where("email", "in", emails).get();
  if (!query.empty)
    return query.docs.map((d) => {
      return {
        ...d.data(),
        id: d.id,
      };
    });
  else return null;
};

export async function getUserDetailByEmail(emails) {
  // don't run if there aren't any ids or a path for the collection
  if (!emails || !emails.length) return [];

  const collectionPath = db.collection("users");
  const batches = [];

  while (emails.length) {
    // firestore limits batches to 10
    const batch = emails.splice(0, 10);

    // add the batch request to to a queue
    batches.push(
      collectionPath
        .where("email", "in", [...batch])
        .get()
        .then((results) =>
          results.docs.map((result) => ({
            ...result.data(),
            id: result.ref.id,
          }))
        )
    );
  }

  // after all of the data is fetched, return it
  return Promise.allSettled(batches).then((resp) => {
    const users = resp.reduce((settledUser, user) => {
      settledUser.push(...user.value);
      return settledUser;
    }, []);

    return users;
  });
}

export const getUserDetails = (id) => async (dispatch) => {
  if (!id) return undefined;
  const query = await db.collection("users").doc(id).get();
  const data = query.data();
  dispatch(fetchUserById(id, data));
};

// export const getUserDetails = async (id) => {
//   if (!id) return undefined;
//   const query = await db.collection("users").doc(id).get();
//   return query.data();
// };

export const getAllUsers =
  ({ dateRange }) =>
    async (dispatch) => {
      dispatch(start());
      try {
        const { toDate, fromDate } = dateRange;

        const query = await db
          .collection("users")
          .where("updated_at", ">=", fromDate)
          .where("updated_at", "<=", toDate)
          .get();

        const data = query.docs.map((user) => {
          return { ...user.data(), id: user.id };
        });

        dispatch(fetchUsers(data));
      } catch (err) {
        dispatch(error());
      }
    };

export const totalUserSize = (institute_id) => async (dispatch) => {
  try {
    const querySnapshot = await db
      .collection("users")
      .where("my_institute", "==", institute_id)
      .limit(500)
      .get();

    dispatch(getUsersLength(querySnapshot.size));
  } catch (error) {
    console.error("Error fetching collection: ", error);
  }
};
