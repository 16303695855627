import * as XLSX from "xlsx";

export const convertExcelToJson = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
        raw: true,
      });
      resolve(convertToJson(data));
    };
    reader.readAsBinaryString(file);
  });
};

const convertToJson = (lines) => {
  var result = [];

  var headers = lines[0];

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i];

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }
  return result; //JSON
};
