import {
  START,
  SUCCESS,
  ERROR,
  FETCH_NEWS,
} from "../actions/news";

export default (
  state = {
      started: false,
      success: false,
      error: false,
      loading: false,
      news: [],
  },
  action
) => {
  switch (action.type) {
      case START:
          return {
              ...state,
              started: true,
              loading: true,
              success: false,
              error: false
          };
      case SUCCESS:
          return {
              ...state,
              started: false,
              success: true,
              loading: false,
              error: false
          };
      case ERROR:
          return {
              ...state,
              started: false,
              loading: false,
              success: false,
              error: true
          };
      case FETCH_NEWS:
          return {
              ...state,
              started: false,
              loading: false,
              success: true,
              error: false,
              news: action.payload
          };
      default:
          return state;
  }
};
