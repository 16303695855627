import { db } from "../firebase/firebase";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const GET_PAYMENT = "GET_PAYMENT";
export const SELECT_INSTITUTE = "SELECT_INSTITUTE";

const start = () => {
  return {
    type: START,
  };
};
const success = (payment) => {
  return {
    type: SUCCESS,
    payload: payment,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

export const fetchPayments = (payments) => {
  return {
    type: FETCH_PAYMENTS,
    payload: payments,
  };
};

export const selectInstitute = (institute) => {
  return {
    type: SELECT_INSTITUTE,
    payload: institute,
  };
};

export const getPayments = () => (dispatch) => {
  dispatch(start());
  db.collection(`payments`)
    .orderBy("timestamp", "desc")
    .onSnapshot((querySnapshot) => {
      const payments = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchPayments(payments));
      dispatch(success());
    });
};
