import { db } from "../firebase/firebase";
import firebase from "firebase";
import { deleteFileFromS3, uploadFileToS3 } from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_NEWS = "FETCH_NEWS";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const fetchNews = (news) => {
  return {
    type: FETCH_NEWS,
    payload: news,
  };
};

export const addNews = (news) => async (dispatch) => {
  dispatch(start());
  try {
    if (news.pdfURL) {
      const fileUrl = await uploadFileToS3(news.pdfURL, uuidv4());
      news.pdfURL = fileUrl;
    }

    if (news.imageURL) {
      const featureImage = await uploadFileToS3(news.imageURL, uuidv4());
      news.imageURL = featureImage;
    }

    const reference = db.collection("news").doc();

    await reference.set({
      ...news,
      id: reference.id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getAllNews =
  ({ pageLimit, lastRefOfNewsDoc, storeLastRefOfNewsDoc, previousNews = [] }) =>
  (dispatch, getState) => {
    dispatch(start());
    const user = getState().auth.userInfo;
    let newsRef = db.collection("news").orderBy("createdAt").limit(pageLimit);

    if (lastRefOfNewsDoc) {
      newsRef = newsRef.startAfter(lastRefOfNewsDoc);
    }

    newsRef.onSnapshot((querySnapshot) => {
      let news = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      storeLastRefOfNewsDoc(querySnapshot.docs[pageLimit]);
      if (user.role === "institute") {
        news = news.filter((nw) => {
          if (nw.institute && nw.institute.value === user.institute_id) {
            return true;
          }
        });
      }

      dispatch(
        fetchNews(
          [...previousNews, ...news].sort((a, b) => b.createdAt - a.createdAt)
        )
      );
      dispatch(success());
    });
  };

export const deleteNews = (news, allNews) => async (dispatch) => {
  const filteredNews = allNews.filter((n) => {
    return n.id !== news[0];
  });
  dispatch(start());
  try {
    await db.collection(`news`).doc(news[0]).delete();
    dispatch(success());
    dispatch(fetchNews([...filteredNews]));
  } catch (error) {
    dispatch(error());
  }
};

export const editNews = (news, cb) => async (dispatch) => {
  dispatch(start());

  const { imageURLToDelete, pdfURLToDelete, ...remainingNews } = news;
  const { pdfURL, imageURL, id } = remainingNews;

  try {
    if (typeof pdfURL === "object") {
      const fileUrl = await uploadFileToS3(pdfURL, uuidv4());
      remainingNews.pdfURL = fileUrl;
      // delete old pdf
      if (pdfURLToDelete) {
        const files = pdfURLToDelete.split("/");
        const imageName = files[files.length - 1];
        await deleteFileFromS3(imageName);
      }
    }
    if (typeof imageURL === "object") {
      const featureImage = await uploadFileToS3(imageURL, uuidv4());

      remainingNews.imageURL = featureImage;
      // delete old image
      if (imageURLToDelete) {
        const files = imageURLToDelete.split("/");
        const imageName = files[files.length - 1];
        await deleteFileFromS3(imageName);
      }
    }

    await db
      .collection(`news`)
      .doc(id)
      .update({
        ...remainingNews,
        updatedAt: Date.now(),
      });

    cb(remainingNews);
  } catch (err) {
    dispatch(error());
  }
};

export const changeNewsStatus = (status, newsId) => (dispatch) => {
  dispatch(start());
  db.collection("news")
    .doc(newsId)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};
