import moment from "moment";

export const getDate = (identifier) => {
  switch (identifier) {
    case "today":
      return moment().startOf("day");
    case "this_week":
      return moment().startOf("week");
    case "this_month":
      return moment().startOf("month");
    default:
      return "";
  }
};

export const getEndDateOfCurrentDate = (date) => {
  return moment(date).endOf().valueOf();
};

export const getStartDateOfAWeekendAhead = (date) => {
  return moment(date).subtract(7, "days").startOf().valueOf();
};

export const getStartDateOfDate = (date) => {
  return moment(date).startOf().valueOf();
};
