import {
  SET_ADD_START,
  SET_ADD_SUCCESS,
  SET_ADD_ERROR,
  // MODEL_UPDATE_START,
  // MODEL_UPDATE_SUCCESS,
  // MODEL_UPDATE_ERROR,
  // MODEL_DELETE_START,
  // MODEL_DELETE_SUCCESS,
  // MODEL_DELETE_ERROR,
  GET_QUESTIONS,
  FETCH_SET,
  ADD_QUESTION,
  PRACTICE_DETAIL,
  FETCH_PRACTISE_SET_WITH_PAGINATION,
} from "../actions/practice_sets";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: true,
    set: {},
    sets: [],
    question: {},
    practiseSetsWithPage: [],
  },
  action
) => {
  switch (action.type) {
    case SET_ADD_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SET_ADD_SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        set: action.set,
      };
    case ADD_QUESTION:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        question: action.question,
      };
    case SET_ADD_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_SET:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        sets: action.sets,
      };
    case FETCH_PRACTISE_SET_WITH_PAGINATION:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        practiseSetsWithPage: action.practiseSetsWithPage,
      };
    case GET_QUESTIONS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        questions: action.questions,
      };
    case PRACTICE_DETAIL:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        practiceDetail: action.practiceDetail,
      };
    default:
      return state;
  }
};
