import {
  START,
  SUCCESS,
  ERROR,
  FETCH_VIDEO,
  FETCH_ALL_VIDEOS,
  DELETE_VIDEOS,
  CREATE_VIDEOS,
  UPDATE_VIDEOS,
  FETCH_VIDEOS_BY_PLAYLIST_ID,
} from "../actions/videos";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    videos: [],
    newVideos: {},
    videosByPlaylistId: {},
  },
  action,
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };
    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_VIDEO:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        videos: action.payload,
      };
    case FETCH_ALL_VIDEOS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newVideos: action.payload,
      };
    case FETCH_VIDEOS_BY_PLAYLIST_ID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        videosByPlaylistId: {
          ...state.videosByPlaylistId,
          [action.payload.playlistId]: action.payload.videos,
        },
      };
    case DELETE_VIDEOS:
      const { videoId, playlistId: _playlistId } = action.payload;
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newVideos: {
          ...state.newVideos,
          items: (state.newVideos?.items ?? []).filter(
            ({ _id }) => _id !== action.payload,
          ),
        },
        videosByPlaylistId: {
          ...state.videosByPlaylistId,
          [_playlistId]: {
            ...state.videosByPlaylistId[_playlistId],
            items: state.videosByPlaylistId[_playlistId].items.filter(
              ({ _id }) => _id !== videoId,
            ),
          },
        },
      };

    case CREATE_VIDEOS:
      const { playlistId } = action.payload;
      const prevVideos = state.videosByPlaylistId[playlistId].items;
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newVideos: {
          ...state.newVideos,
          total: state.newVideos.total + 1,
          items: [...(state.newVideos?.items ?? []), action.payload],
        },
        videosByPlaylistId: {
          ...state.videosByPlaylistId,
          [playlistId]: {
            ...state.videosByPlaylistId[playlistId],
            items: [...prevVideos, action.payload],
          },
        },
      };
    case UPDATE_VIDEOS:
      const updatedVideos = state.videosByPlaylistId[
        action.payload.playlistId
      ].items.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        newVideos: {
          ...state.newVideos,
          total: state.newVideos.total + 1,
          items: [...(state.newVideos?.items ?? [])].map((item) => {
            if (item._id === action.payload._id) {
              return action.payload;
            } else {
              return item;
            }
          }),
        },
        videosByPlaylistId: {
          ...state.videosByPlaylistId,
          [action.payload.playlistId]: {
            ...state.videosByPlaylistId[action.payload.playlistId],
            items: updatedVideos,
          },
        },
      };
    default:
      return state;
  }
};
