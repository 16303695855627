import {
  START,
  SUCCESS,
  ERROR,
  FETCH_PAYMENTS,
  GET_PAYMENT,
  SELECT_INSTITUTE,
} from "../actions/payments";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    payments: [],
    payment: {},
    institute: {},
  },
  action
) => {
  switch (action.type) {
    case START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
      };

    case ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_PAYMENTS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        payments: action.payload,
      };
    case GET_PAYMENT:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        payment: action.payload,
      };
    case SELECT_INSTITUTE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        institute: action.payload,
      };

    default:
      return state;
  }
};
