import {
  MODEL_SET_START,
  MODEL_SET_SUCCESS,
  MODEL_SET_ERROR,
  GET_MODEL_QUESTIONS,
  FETCH_MODEL_SET,
  FETCH_MODEL_SET_FOR_PAGINATION,
  ADD_MODEL_QUESTION,
  GET_MODEL_GROUPS,
  MODEL_DETAIL,
  GET_MODEL_SCORE,
  SET_SCORE_REVIEW,
  MODEL_SET_BY_ID,
} from "../actions/model_sets";

export default (
  state = {
    started: false,
    success: false,
    error: false,
    loading: false,
    set: {},
    sets: [],
    question: {},
    scores: [],
    scoreReviews: [],
    modelSetsForPaginaiton: [],
  },
  action
) => {
  switch (action.type) {
    case MODEL_SET_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };
    case MODEL_SET_SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        modelset: action.modelset,
      };
    case MODEL_SET_BY_ID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        [action.payload.id]: action.payload.modelSet,
      };

    case MODEL_DETAIL:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        modelDetail: action.modelDetail,
      };
    case ADD_MODEL_QUESTION:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        model_question: action.model_question,
      };
    case GET_MODEL_SCORE:
      return {
        ...state,
        scores: action.scores,
      };
    case MODEL_SET_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };
    case FETCH_MODEL_SET_FOR_PAGINATION:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        modelSetsForPaginaiton: action.modelSetsForPaginaiton,
      };

    case FETCH_MODEL_SET:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        modelsets: action.modelsets,
      };
    case GET_MODEL_QUESTIONS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        model_questions: action.model_questions,
      };
    case GET_MODEL_GROUPS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        model_groups: action.model_groups,
      };
    case SET_SCORE_REVIEW:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        scoreReviews: action.scoreReviews,
      };
    default:
      return state;
  }
};
