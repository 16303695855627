import React, { Component } from "react";
import { connect } from "react-redux";
import ResetPasswordForm from "./ResetPasswordForm";
import { handlePasswordReset } from "../../actions/auth";

import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
class ResetPassword extends Component {
  state = {
    code: "",
  };

  handleSubmit = async (data) => {
    this.props.handlePasswordReset({ ...data, code: this.state.code });
  };
  getParameterByName = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(this.props.location.search);
    return results == null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  componentWillMount = () => {
    var oobCode = this.getParameterByName("oobCode");
    this.setState({ code: oobCode });
  };

  toggle = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Reset Password</h1>
                    <p className="text-muted">
                      Please enter your new password.
                    </p>
                    <ResetPasswordForm
                      forget={true}
                      handleSubmit={this.handleSubmit}
                      cancel={this.toggle}
                      loading={this.props.loading}
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handlePasswordReset: (data) =>
      dispatch(handlePasswordReset(data, ownProps)),
  };
};
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
