import { db } from "../firebase/firebase";

export const RESULT_START = "RESULT_START";
export const RESULT_ERROR = "RESULT_ERROR";
export const FETCH_RESULT = "FETCH_RESULT";
export const RESULT_SUCCESS = "RESULT_SUCCESS";

const start = () => {
  return {
    type: RESULT_START,
  };
};

const success = () => {
  return {
    type: RESULT_SUCCESS,
  };
};

const error = () => {
  return {
    type: RESULT_ERROR,
  };
};

const fetchResults = (payload) => {
  return {
    type: FETCH_RESULT,
    payload,
  };
};

export const getAllResults =
  ({
    storeRefOfResultDoc,
    lastRefOfResultDoc,
    limitTofetch,
    previousFetchedResult = [],
  }) =>
  async (dispatch) => {
    dispatch(start());
    let resultRef = db.collection("results").limit(limitTofetch);

    if (lastRefOfResultDoc) {
      resultRef = resultRef.startAfter(lastRefOfResultDoc);
    }
    resultRef.onSnapshot((querySnapshot) => {
      storeRefOfResultDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      const resultsData = [];
      querySnapshot.docs.forEach((doc) => {
        resultsData.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      if (resultsData.length > 0) {
        dispatch(fetchResults([...previousFetchedResult, ...resultsData]));
      }
      dispatch(success());
    });
  };
