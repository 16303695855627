import auth from "./auth";
import news from "./news";
import users from "./user";
import units from "./units";
import videos from "./video";
import groups from "./groups";
import settings from "./settings";
import payments from "./payments";
import playlists from "./playlist";
import model_sets from "./model_sets";
import institutes from "./institutes";
import paymentsV2 from "./paymentsV2";
import bookAudios from "./book_audios";
import { combineReducers } from "redux";
import subscription from "./subscription";
import pollQuestions from "./pollQuestion";
import setPackaging from "./set_packaging";
import practice_sets from "./practice_sets";
import partnerShipRequests from "./partnerShipRequests";
import { reducer as toastrReducer } from "react-redux-toastr";
import results from "./result";

import helperReducer from "./helper";

export default combineReducers({
  auth,
  units,
  users,
  groups,
  videos,
  results,
  settings,
  payments,
  playlists,
  model_sets,
  institutes,
  news: news,
  bookAudios,
  paymentsV2,
  setPackaging,
  subscription,
  pollQuestions,
  practice_sets,
  partnerShipRequests,
  toastr: toastrReducer,
  helper: helperReducer,
});
