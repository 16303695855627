import {
  FETCH_POLL_QUESTION,
  POLL_QUESTION_START,
  POLL_QUESTION_ERROR,
} from "../actions/pollQuestions";

const initialState = {
  started: false,
  success: false,
  error: false,
  loading: false,
  pollQuestions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POLL_QUESTION_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };

    case POLL_QUESTION_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };

    case FETCH_POLL_QUESTION:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        pollQuestions: action.payload,
      };

    default:
      return state;
  }
};
