import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class ResetPasswordForm extends Component {
  state = {
    password: "",
    confirm_password: "",
  };

  onChangeHandler = (e) => {
    let err = {};
    if (e.target.name === "password" || e.target.name === "confirm_password") {
      err.password = null;
    }
    this.setState({
      ...this.state,
      error: err,
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let err = {};

    if (this.state.password.trim() !== this.state.confirm_password.trim()) {
      err.password = "Password donot match.";
    }

    if (Object.keys(err).length > 0) {
      this.setState({
        ...this.state,
        error: err,
      });
    } else {
      if (window.confirm("Are you sure you want to reset password?")) {
        const submitData = {
          password: this.state.password,
        };
        this.props.handleSubmit(submitData);
        this.setState({
          error: err,
        });
      }
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="email">New Password</label>
            <div className="input-group mb-1">
              <input
                type="password"
                id="password"
                required
                name="password"
                value={this.state.password}
                autoComplete="new-password"
                className="form-control"
                onChange={this.onChangeHandler}
                placeholder="At least 6 characters"
              />
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="email">Confirm Password</label>
            <div className="input-group mb-1">
              <input
                type="password"
                placeholder="At least 6 characters"
                id="confirm_password"
                required
                name="confirm_password"
                value={this.state.confirm_password}
                className="form-control"
                onChange={this.onChangeHandler}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.error && this.state.error.password && (
              <div className="alert alert-danger">
                {this.state.error.password}
              </div>
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 d-flex align-items-center">
            <button type="submit" className="btn btn-success mr-1">
              {this.props.loading ? <CircularProgress /> : "Submit"}
            </button>
            <button
              type="button"
              onClick={this.props.cancel}
              className="btn btn-danger"
            >
              {this.props.forget ? "Back to Login" : "Cancel"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ResetPasswordForm;
