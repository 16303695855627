import { db } from "../firebase/firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_NEW_SUBSCRIPTION = "FETCH_NEW_SUBSCRIPTION";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const fetchSubs = (subs) => {
  return {
    type: FETCH_SUBSCRIPTION,
    payload: subs,
  };
};

const fetchNewSubs = (subs) => {
  return {
    type: FETCH_NEW_SUBSCRIPTION,
    payload: subs,
  };
};

export const addSubscription = (sub, subscriptions) => async (dispatch) => {
  dispatch(start());

  try {
    const { usersIdEmail = [], ...restSub } = sub;
    const usersEmail = usersIdEmail.map((x) => x.email);

    const typeField = sub?.videoId ? "videoId" : "playlistId";
    const typeId = sub?.videoId ? sub?.videoId : sub?.playlistId;

    const subscribedUsersRef = await db
      .collectionGroup("playlist_or_video_subscriptions")
      .where("userEmail", "in", usersEmail)
      .where(typeField, "==", typeId)
      .get();

    const alreadySubscribedUserEmailsSet = new Set(
      subscribedUsersRef.docs.map((x) => x.data().userEmail),
    );

    const notSubscribedUserEmails = usersIdEmail.filter(
      (e) => !alreadySubscribedUserEmailsSet.has(e.email),
    );

    const expiresInMillis = sub?.expiryAt ? sub?.expiryAt * 86400000 : null;

    const test = subscribedUsersRef.docs.map((docRef) => {
      if (docRef.data().userEmail) {
        return docRef.ref.update({
          updatedAt: Date.now(),
          expiresIn: firebase.firestore.FieldValue.increment(expiresInMillis),
          expiryAt: firebase.firestore.FieldValue.increment(sub.expiryAt),
        });
      }
    });

    await Promise.all(test);

    const refIds = await notSubscribedUserEmails.map(async (x) => {
      const newDocRef = db
        .collection("users")
        .doc(x.id)
        .collection("playlist_or_video_subscriptions")
        .doc();
      const currentTime = firebase.firestore.Timestamp.now().toMillis();

      const subscriptionTobeUpdated = {
        ...restSub,
        id: newDocRef.id,
        userId: x.id,
        userEmail: x.email,
        createdAt: currentTime,
        purchasedAt: currentTime,
        expiresIn:
          expiresInMillis !== null ? currentTime + expiresInMillis : null,
        updatedAt: null,
        renewedAt: null,
      }

      await newDocRef.set(subscriptionTobeUpdated);
      dispatch(
        fetchSubs([
          { ...sub, userId: x.id, id: newDocRef.id },
          ...subscriptions,
        ]),
      );

      dispatch(fetchNewSubs({
        playlistId: sub.playlistId, playlists: [
          ...subscriptions,
          { ...subscriptionTobeUpdated, id: newDocRef.id },
        ]
      }))

    });

    await Promise.all(refIds);

    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const updateSubscription = (sub, subscriptions) => async (dispatch) => {
  const updatedSubs = subscriptions.map((subs) => {
    if (subs.id === sub.id) {
      return sub;
    }
    return subs;
  });
  dispatch(start());

  try {
    const currentTime = firebase.firestore.Timestamp.now().toMillis();
    await db
      .collection("users")
      .doc(sub?.userId)
      .collection("playlist_or_video_subscriptions")
      .doc(sub?.id)
      .update({
        ...sub,
        updatedAt: currentTime,
        renewedAt: firebase.firestore.FieldValue.arrayUnion(Date.now()),
      });
    dispatch(fetchSubs(updatedSubs));
    dispatch(fetchNewSubs({ playlistId: sub.playlistId, playlists: updatedSubs }));

    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const fetchSubscription =
  ({
    storeRefOfSubscriptionDoc,
    lastRefOfSubscriptionDoc,
    limitTofetch,
    subscriptions = [],
    playlistId
  }) =>
    async (dispatch) => {
      dispatch(start());
      try {
        let ref = db
          .collectionGroup("playlist_or_video_subscriptions")
          .orderBy("purchasedAt", "desc")
          .limit(limitTofetch);

        if (playlistId !== undefined) {
          ref = ref.where("playlistId", "==", playlistId)

        }
        if (lastRefOfSubscriptionDoc) {
          ref = ref.startAfter(lastRefOfSubscriptionDoc);
        }

        const response = await ref.get();

        const referenceOfLastItem = response.docs[limitTofetch - 1]
        if (referenceOfLastItem === undefined) {
          storeRefOfSubscriptionDoc(null);
        } else {
          storeRefOfSubscriptionDoc(response.docs[limitTofetch - 1]);

        }


        const finalSubscriptionData = response.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (playlistId) {
          dispatch(fetchNewSubs({ playlistId, playlists: [...subscriptions, ...finalSubscriptionData] }))
        } else {
          dispatch(fetchSubs([...subscriptions, ...finalSubscriptionData]));
        }
        dispatch(success());
      } catch (err) {
        dispatch(error());
      }
    };

export const delSubscription = (sub, subscriptions) => async (dispatch) => {
  const filterSubs = subscriptions.filter((subs) => subs.id !== sub.id);

  dispatch(start());
  const { id, videoId, playlistId, userId } = sub;
  try {
    await db
      .collection("users")
      .doc(userId)
      .collection("playlist_or_video_subscriptions")
      .doc(id)
      .delete();

    const type = playlistId ? "playlists" : "videos";
    const typeId = videoId ? videoId : playlistId;
    await db
      .collection(type)
      .doc(typeId)
      .update({
        subscriptionIds: firebase.firestore.FieldValue.arrayRemove(id),
      });

    dispatch(fetchNewSubs({ playlistId, playlists: filterSubs }));
    dispatch(fetchSubs(filterSubs));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

function getPaymentData(collectionName, videoOrPayLists) {
  if (collectionName === "playlists") {
    const {
      price,
      percentDiscount,
      instituteId,
      flatDiscount,
      id,
      userEmail,
      userId,
      userName,
      name,
    } = videoOrPayLists; // for playlists

    return {
      amount: price ?? 0,
      user: userId,
      user_email: userEmail,
      user_name: userName ?? "",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      asset_id: id,
      asset_name: name,
      asset_type: collectionName,
      discount: flatDiscount || percentDiscount,
      institute_id: instituteId,
      institute_name: "",
      payment_method: "web",
    };
  } else {
    const { type, title, id, userId, userEmail, userName } = videoOrPayLists;
    return {
      amount: 0,
      user: userId,
      user_email: userEmail,
      user_name: userName ?? "",
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      asset_id: id,
      asset_name: title,
      asset_type: collectionName,
      discount: 0,
      institute_id: "",
      institute_name: "",
      payment_method: "web",
    };
  }
}


export const searchSubscriptionByEmail = async (email) => {
  if (!email || email?.length === 0) return [];

  try {
    const ref = db
      .collectionGroup("playlist_or_video_subscriptions")
      .where("userEmail", ">=", email)
      .where("userEmail", "<=", email + "\uf8ff")
      .limit(100);
    const snapshot = await ref.get();

    if (!snapshot.empty) {
      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};