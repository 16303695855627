import axios from "axios";
import config from "./config";

export const API_URL = config.api_url;

export const backend = axios.create({
  baseURL: API_URL + "/v1/private/",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

backend.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");

    config.headers.setAuthorization(`Bearer ${token}`);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
