import { db, storage } from "../firebase/firebase";
import firebase from "firebase";
import {
  getFileName,
  uploadFileToS3,
  uploadInstituteResource,
} from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const ADD_SETTING = "ADD_SETTING";
export const FETCH_SETTING = "FETCH_SETTING";
export const FETCH_INSTITUTE = "FETCH_INSTITUTE";
export const FETCH_ADS = "FETCH_ADS";

const start = () => {
  return {
    type: START,
  };
};
const success = (setting) => {
  return {
    type: SUCCESS,
    payload: setting,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const fetchSettings = (settings) => {
  return {
    type: FETCH_SETTING,
    payload: settings,
  };
};

const fetchInstitute = (institutes) => {
  return {
    type: FETCH_INSTITUTE,
    payload: institutes,
  };
};
const fetchAds = (ads) => {
  return {
    type: FETCH_ADS,
    payload: ads,
  };
};

export const getAllSettings = () => (dispatch) => {
  db.collection(process.env.REACT_APP_SETTINGS)
    .doc("settings")
    .onSnapshot((querySnapshot) => {
      const settings = querySnapshot.data();
      dispatch(fetchSettings(settings));
    });
};

export const addCourse = (course) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_SETTINGS)
    .doc("settings")
    .update({
      courses: firebase.firestore.FieldValue.arrayUnion(course),
    })
    .then((querySnapshot) => {
      dispatch(success({ course }));
    })
    .catch((err) => {
      dispatch(error());
    });
};
export const addArea = (area) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_SETTINGS)
    .doc("settings")
    .update({
      areas: firebase.firestore.FieldValue.arrayUnion(area),
    })
    .then((querySnapshot) => {
      dispatch(success({ area: area }));
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const deleteSetting = (setting, type) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_SETTINGS)
    .doc("settings")
    .update({
      [type]: firebase.firestore.FieldValue.arrayRemove(setting),
    })
    .then((querySnapshot) => {
      dispatch(success({ [type]: setting }));
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const getAllInstitute = () => (dispatch) => {
  dispatch(start());
  db.collection("institutes")
    .orderBy("createdAt", "desc")
    .onSnapshot((querySnapshot) => {
      const institutes = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchInstitute(institutes));
      dispatch(success());
    });
};

export const addInstitute = (institute) => async (dispatch) => {
  dispatch(start());

  let logoUrl = "";
  let coverUrl = "";
  try {
    if (institute.logo) {
      logoUrl = await uploadInstituteResource(
        institute.logo,
        uuidv4(),
        uuidv4(),
        "video"
      );
    }
    if (institute.cover) {
      coverUrl = await uploadInstituteResource(
        institute.cover,
        uuidv4(),
        uuidv4(),
        "video"
      );
    }

    db.collection("institutes")
      .add({
        ...institute,
        logo: logoUrl,
        cover: coverUrl,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((snapshot) => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(error());
      });
  } catch (e) {
    alert("Failed to create institute");
  }
};
export const updateInstitute = (institute, id) => async (dispatch) => {
  dispatch(start());
  let logoUrl = institute.logo || "";
  let coverUrl = institute.cover || "";
  try {
    if (institute.logo && typeof institute.logo !== "string") {
      logoUrl = await uploadInstituteResource(
        institute.logo,
        uuidv4(),
        uuidv4(),
        "video"
      );
    }
    if (institute.cover && typeof institute.cover !== "string") {
      coverUrl = await uploadInstituteResource(
        institute.cover,
        uuidv4(),
        uuidv4(),
        "video"
      );
    }

    db.collection("institutes")
      .doc(id)
      .update({
        ...institute,
        logo: logoUrl,
        cover: coverUrl,
        bank_details: institute.bank_details || null,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((snapshot) => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(error());
      });
  } catch (e) {
    alert("Failed to update institute");
  }
};
export const deleteInstitute = (id) => (dispatch) => {
  dispatch(start());
  db.collection("institutes")
    .doc(id)
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const changeInstituteStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection("institutes")
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};
export const changeAdsStatus = (status, id) => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_ADS)
    .doc(id)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const addAds = (data) => async (dispatch) => {
  dispatch(start());
  try {
    const url = await uploadFileToS3(data.ad_image, uuidv4());
    await db.collection(process.env.REACT_APP_ADS).add({
      ...data,
      ad_image: url,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getAllAds = () => (dispatch) => {
  dispatch(start());
  db.collection(process.env.REACT_APP_ADS)
    .orderBy("created_at", "desc")
    .onSnapshot((querySnapshot) => {
      const ads = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchAds(ads));
      dispatch(success());
    });
};

export const deleteAds = (ads) => async (dispatch) => {
  dispatch(start());
  try {
    await db
      .collection(process.env.REACT_APP_ADS)
      .doc(ads.id)
      .delete()
      .then((snapshot) => {
        dispatch(success());
      });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const updateAds = (newads, id, oldads) => async (dispatch) => {
  dispatch(start());
  try {
    if (newads.ad_image || newads.ad_image_cleared) {
      const fileName = getFileName(oldads.ad_image) || uuidv4();
      const url = await uploadFileToS3(newads.ad_image, fileName);
      await db
        .collection(process.env.REACT_APP_ADS)
        .doc(id)
        .update({
          ...newads,
          ad_image: url,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      dispatch(success());
    } else {
      await db
        .collection(process.env.REACT_APP_ADS)
        .doc(id)
        .update({
          ...newads,
          ad_image: oldads.ad_image,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      dispatch(success());
    }
  } catch (err) {
    dispatch(error());
  }
};
