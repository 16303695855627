import { db } from "../firebase/firebase";
import firebase from "firebase";
import { deleteFileFromS3, uploadFileToS3 } from "../helpers/awsHelper";
import { v4 as uuidv4 } from "uuid";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_ALL = "FETCH_ALL";

const start = () => {
  return {
    type: START,
  };
};
const success = () => {
  return {
    type: SUCCESS,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

const fetchAll = (bookAudios) => {
  return {
    type: FETCH_ALL,
    payload: bookAudios,
  };
};

export const addBookAudio = (bookAudio) => async (dispatch) => {
  dispatch(start());

  const uploadPromises = bookAudio?.audios.map((audio) => {
    return uploadFileToS3(audio.audio, uuidv4());
  });

  const coverImage = await uploadFileToS3(bookAudio.cover_image_url, uuidv4());

  if (bookAudio.pdf_url) {
    const pdf = await uploadFileToS3(bookAudio.pdf_url, uuidv4());
    bookAudio.pdf_url = pdf;
  }

  const audios = await Promise.all(uploadPromises);
  bookAudio.audios = bookAudio.audios.map((audio, i) => {
    audio.audio = audios[i];
    return audio;
  });

  db.collection(`book_audios`)
    .add({
      ...bookAudio,
      cover_image_url: coverImage,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const fetchBookAudios = () => (dispatch) => {
  dispatch(start());
  db.collection("book_audios")
    .orderBy("created_at", "desc")
    .onSnapshot((querySnapshot) => {
      const bookAudios = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(fetchAll(bookAudios));
      dispatch(success());
    });
};

export const deleteBookAudio = (bookAudio) => async (dispatch) => {
  dispatch(start());

  const { audios, cover_image_url, id } = bookAudio;

  // delete images

  if (audios.length > 0) {
    const deleteAudio = audios.map((audio) => {
      return deleteFileFromS3(audio.audio_name);
    });

    await Promise.all(deleteAudio);
  }

  const files = cover_image_url.split("/");
  const imageName = files[files.length - 1];
  await deleteFileFromS3(imageName);

  db.collection(`book_audios`)
    .doc(id)
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const updateBookAudio = (bookAudio, id) => async (dispatch) => {
  dispatch(start());

  const { deleteCoverImageURL, audiosUrlToDelete, ...remainingAudio } =
    bookAudio;

  const previousAudio = bookAudio.audios.filter(
    (audio) => typeof audio.audio === "string"
  );

  const latestAudios = bookAudio?.audios.filter(
    (a) => typeof a.audio === "object"
  );

  const uploadPromises = latestAudios.map((audio) => {
    return uploadFileToS3(audio.audio, uuidv4());
  });

  const audios = await Promise.all(uploadPromises);
  const newAudios = latestAudios.map((audio, i) => {
    audio.audio = audios[i];
    return audio;
  });

  remainingAudio.audios = [...previousAudio, ...newAudios];

  try {
    if (typeof bookAudio.cover_image_url === "object") {
      const coverImage = await uploadFileToS3(
        remainingAudio.cover_image_url,
        uuidv4()
      );
      const files = deleteCoverImageURL.split("/");
      const imageName = files[files.length - 1];
      await deleteFileFromS3(imageName);
      remainingAudio.cover_image_url = coverImage;
    }

    if (typeof bookAudio.pdf_url === "object") {
      const pdf = await uploadFileToS3(bookAudio.pdf_url, uuidv4());
      remainingAudio.pdf_url = pdf;
    }

    if (audiosUrlToDelete.length > 0) {
      const deleteAudio = audiosUrlToDelete
        .filter((audio) => typeof audio.audio === "string")
        .map((audio) => {
          return deleteFileFromS3(audio.audio_name);
        });

      await Promise.all(deleteAudio);
    }

    await db
      .collection(`book_audios`)
      .doc(id)
      .update({
        ...remainingAudio,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      });
    dispatch(success());
  } catch (error) {
    dispatch(error());
  }
};
