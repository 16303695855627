import { db } from "../firebase/firebase";
import firebase from "firebase";
import { uploadFileToS3 } from "../helpers/awsHelper";
import { v4 as uuidv4 } from 'uuid';

export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_GROUP_CATEORIES = "FETCH_GROUP_CATEORIES";
export const FETCH_GROUPS = "FETCH_GROUPS";

const start = () => {
    return {
        type: START
    };
};
const success = () => {
    return {
        type: SUCCESS,
    };
};
const error = () => {
    return {
        type: ERROR
    };
};

const fetchGroupCategories = categories => {
    return {
        type: FETCH_GROUP_CATEORIES,
        payload: categories
    };
};

const fetchGroups = groups => {
    return {
        type: FETCH_GROUPS,
        payload: groups
    };
};

export const addGroupCategoryAction = (category) => dispatch => {
    dispatch(start());
    db.collection(`group_categories`)
      .add({
          ...category,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(snapshot => {
          dispatch(success());
      })
      .catch(err => {
          dispatch(error());
          console.log(err)
      });
};


export const fetchGroupCategoriesAction = () => dispatch => {
    dispatch(start());
    db.collection("group_categories")
        .orderBy("prefix", "asc")
        .onSnapshot(querySnapshot => {
            const categoriesGroups = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
            dispatch(fetchGroupCategories(categoriesGroups));
            dispatch(success());
        });
};

export const addGroupAction = (group) =>async dispatch => {
  dispatch(start());
  const url = await uploadFileToS3(group.image, uuidv4())
  group.image=url;
  delete group.hasImage;
  db.collection(`groups`)
    .add({
        ...group,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(snapshot => {
        dispatch(success());
    })
    .catch(err => {
        dispatch(error());
        console.log(err)
    });
};

export const fetchGroupsAction = () => dispatch => {
    dispatch(start());
    db.collection("groups")
        .orderBy("prefix", "asc")
        .onSnapshot(querySnapshot => {
            const groups = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
            dispatch(fetchGroups(groups));
            dispatch(success());
        });
};


export const deleteGroupAction = (group) => dispatch => {
    dispatch(start())
    db.collection(`groups`)
    .doc(group.id)
        .delete()
        .then(snapshot => {
            dispatch(success());
        })
        .catch(err => {
            dispatch(error());
        });
    }

export const updateGroupCategoryAction = (bookAudio,id) => dispatch => {
    dispatch(start());
    db.collection(`book_audios`).doc(id)
    .update({
        ...bookAudio,
        updated_at: firebase.firestore.FieldValue.serverTimestamp()
    })
    .then(snapshot => {
        dispatch(success());
    })
    .catch(err => {
        dispatch(error());
    });

};
