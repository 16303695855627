import {
  FETCH_USERS_BY_DATE_RANGE,
  FETCH_USER_BY_ID,
  TOTAL_USERS,
  USER_ERROR,
  USER_START,
  USER_SUCCESS,
} from "../actions/user";

const initialState = {
  started: false,
  success: false,
  error: false,
  loading: false,
  users: null,
  totalUserSize: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_START:
      return {
        ...state,
        started: true,
        loading: true,
        success: false,
        error: false,
      };

    case USER_SUCCESS:
      return {
        ...state,
        started: false,
        success: true,
        loading: false,
        error: false,
        user: action.user,
      };

    case USER_ERROR:
      return {
        ...state,
        started: false,
        loading: false,
        success: false,
        error: true,
      };

    case FETCH_USERS_BY_DATE_RANGE:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        users: action.payload,
      };

    case FETCH_USER_BY_ID:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        [action.payload.id]: action.payload.user,
      };

    case TOTAL_USERS:
      return {
        ...state,
        started: false,
        loading: false,
        success: true,
        error: false,
        totalUserSize: action.totalUserSize,
      };

    default:
      return state;
  }
};
