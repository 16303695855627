import { db, myFirebase, myFirebase2 } from "../firebase/firebase";
import { toastr } from "react-redux-toastr";
import { selectInstitute, fetchPayments } from "./payments";
import { v4 } from "uuid";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const LOAD_USER = "LOAD_USER";

const start = () => {
  return {
    type: START,
  };
};
const success = (userInfo) => {
  return {
    type: SUCCESS,
    payload: userInfo,
  };
};
const error = (message) => {
  return {
    type: ERROR,
    payload: message,
  };
};

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};
const loadUser = (user) => {
  return {
    type: LOAD_USER,
    user,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const loginUser = (email, password) => (dispatch) => {
  dispatch(requestLogin());
  dispatch(start());
  myFirebase
    .firestore()
    .collection("users")
    .where("email", "==", email)
    .get()
    .then((res) => {
      if (!res.empty) {
        const userRef = res.docs && res.docs[0] ? res.docs[0] : null;
        const userData = userRef.data();
        localStorage.setItem("user_id", userRef.id);
        if (userData.status === "Deactive") {
          dispatch(error("Your account is deactivated."));
          return;
        }
        myFirebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            if (user !== null) {
              if (userData.role === "institute" || userData.role === "admin") {
                localStorage.setItem("user_role", userData.role);
                dispatch(loadUser(userData));
                dispatch(receiveLogin(user));
                if (userData.role === "institute") {
                  localStorage.setItem("institute_id", userData.institute_id);
                  dispatch(
                    selectInstitute({
                      id: userData.institute_id,
                      name: userData.name,
                    })
                  );
                }
                dispatch(verifySuccess());
                dispatch(success());
              } else if (!userData.role || userData.role === "user") {
                dispatch(loginError());
                dispatch(error());
                return;
              }
            }
          })
          .catch((err) => {
            dispatch(loginError());
            dispatch(error());
            //Do something with the error if you want!
          });
      } else {
        dispatch(loginError());
        dispatch(error());
      }
    })
    .catch((err) => {
      dispatch(loginError());
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  myFirebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_role");
      if (localStorage.getItem("institute_id")) {
        localStorage.removeItem("institute_id");
      }
      if (localStorage.getItem("institute_name")) {
        localStorage.removeItem("institute_name");
      }
      dispatch(selectInstitute({}));
      dispatch(fetchPayments([]));
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());

  myFirebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      myFirebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          localStorage.setItem("token", idTokenResult.token);
        });

      localStorage.setItem("user_id", user.uid);

      const email = user.email;

      const collectionRef = myFirebase
        .firestore()
        .collection("users")
        .where("email", "==", email);

      collectionRef.get().then((res) => {
        if (res) {
          const currentUser = res.docs.map((doc) => ({
            ...doc.data(),
            uid: doc.id,
          }))[0];

          if (currentUser?.role === "institute") {
            if (!currentUser.id) {
              myFirebase
                .firestore()
                .collection("users")
                .doc(currentUser.uid)
                .update({ id: user.uid });
            }

            if (currentUser.status === "Deactive") {
              dispatch(logoutUser());
              dispatch(error("Your Account is deactivated."));
              return;
            }

            localStorage.setItem("user_role", currentUser.role);
            localStorage.setItem("institute_id", currentUser.institute_id);
            dispatch(loadUser(currentUser));
            dispatch(receiveLogin(user));
            dispatch(
              selectInstitute({
                id: currentUser.institute_id,
                name: currentUser.name,
              })
            );
            dispatch(verifySuccess());
          } else {
            myFirebase
              .firestore()
              .collection("users")
              .doc(user.uid)
              .get()
              .then((res) => {
                if (res.data().status === "Deactive") {
                  dispatch(logoutUser());
                  dispatch(error("Your Account is deactivated."));
                  return;
                }
                if (res.data().role == "admin") {
                  localStorage.setItem("user_role", res.data().role);
                  dispatch(loadUser(res.data()));
                  dispatch(receiveLogin(user));

                  dispatch(verifySuccess());
                } else if (!res.data().role || res.data().role == "user") {
                  // dispatch(loginError());
                  return;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    } else {
      dispatch(requestLogout());
      dispatch(receiveLogout());
      dispatch(verifySuccess());
    }
  });
};

export const registerInstitute =
  ({ password, ...data }) =>
  async (dispatch) => {
    dispatch(start());
    const user = await myFirebase
      .firestore()
      .collection("users")
      .where("email", "==", data.email)
      .limit(1)
      .get();
    try {
      let userId;
      if (!user.empty) {
        const dataRef = user.docs[0] && user.docs[0];
        const data = dataRef.data();
        if (data.institute_id) {
          dispatch(error("Institute with the email already exists."));
          return;
        } else {
          userId = dataRef.id;
        }
      } else {
        try {
          const snap = await myFirebase2
            .auth()
            .createUserWithEmailAndPassword(data.email, password);
          userId = snap.user.uid;
        } catch (err) {
          console.log(err);
          userId = v4();
        }
      }
      await myFirebase
        .firestore()
        .collection("users")
        .doc(userId)
        .set(
          {
            ...data,
            created_at: myFirebase.firestore.FieldValue.serverTimestamp(),
            updated_at: myFirebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      await myFirebase
        .firestore()
        .collection("institutes")
        .doc(data.institute_id)
        .update({ user_id: userId });
      dispatch(success());
    } catch (err) {
      dispatch(error("Something went wrong."));
      console.log(error);
    }
  };

export const checkInstituteEmail = async (email) => {
  const user = await myFirebase
    .firestore()
    .collection("users")
    .where("email", "==", email)
    .get();

  if (!user.empty) {
    const dataRef = user.docs[0] && user.docs[0];
    const data = dataRef.data();
    if (data.institute_id) {
      return true;
    }
  }
  return false;
};

export const resetPassword = (data) => async (dispatch) => {
  dispatch(start());
  const user = myFirebase.auth().currentUser;
  try {
    await user.updatePassword(data.password);
    dispatch(success());
    toastr.success("Success", "Password reset successfully.");
  } catch (err) {
    dispatch(error());
    toastr.error("Password reset failed.", err.message);
  }
};

export const forgetPassword = (email) => async (dispatch) => {
  dispatch(start());
  try {
    const snap = await myFirebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();
    if (!snap.empty && snap.docs.length > 0) {
      const userSnap = snap.docs && snap.docs[0] ? snap.docs[0] : null;
      const userData = userSnap.data();

      if (userData.status === "Deactive") {
        toastr.error("Deactivated !", "Your Account has been deactivated.");
        dispatch(error());
        return;
      }
      const emailSent = await myFirebase.auth().sendPasswordResetEmail(email);
      dispatch(success());
      toastr.success(
        "Email sent",
        "Password reset link has been sent to your email."
      );
    } else {
      toastr.error("Oops!", "Email doesnot exists.");
      dispatch(error());
    }
  } catch (err) {
    toastr.error("Sorry!", err.message);
    dispatch(error());
  }
};

export const handlePasswordReset = (data, props) => {
  const code = data.code;
  const newPassword = data.password;
  return (dispatch) => {
    dispatch(start());
    myFirebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        myFirebase
          .auth()
          .confirmPasswordReset(code, newPassword)
          .then(() => {
            toastr.success("Bravo !!", "Password Changed Successfully");
            dispatch(success());
            props.history.push("/login");
          })
          .catch((err) => {
            toastr.error("Oops !", "Error Changing Password");
            dispatch(error());
          });
      })
      .catch((err) => {
        dispatch(error());
      });
  };
};
